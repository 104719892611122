<template>
 
 <div class="qr-container">
  <br />
  <div v-if="!identified && !displayBig">
    
    <div class="button-white" @click="displayBig=true" v-if="language=='fr'"><VueQRCodeComponent class="qrcode-mini" v-if="loading==false && !identified" size="32" :text="'https://preprod.visages.com/identify-device/'+device_identification.id"></VueQRCodeComponent> <span  class="qrcode-mini-text" >Scannez ce QR Code avec votre appareil</span></div>
    <div class="button-white" @click="displayBig=true" v-else><VueQRCodeComponent class="qrcode-mini" v-if="loading==false && !identified" size="32" :text="'https://preprod.visages.com/identify-device/'+device_identification.id"></VueQRCodeComponent><span class="qrcode-mini-text">Scan QR code to find out which device you have</span></div>
   
  </div>
  <div v-if="displayBig && !identified">
    <center>
      <VueQRCodeComponent class="qrcode-id" v-if="loading==false && !identified" size="150" :text="'https://preprod.visages.com/identify-device/'+device_identification.id"></VueQRCodeComponent>
      <br /><br />
     
    </center>
  </div>
  <div  class="text" v-if="identified">
        <span v-if="language=='fr'">Appareil détecté :</span><span v-else>Device detected:</span> {{ device_identification.device }}
      <br /> <br />
      </div>
      
      <div class="text" v-else-if="loading">
        <span v-if="language=='fr'">Chargement...</span>
        <span v-else>Loading...</span>
        <br />
      </div>
</div>
</template>


<script>
import {APIRequest} from '@simb2s/senseye-sdk';

import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader';
import QrcodeVue from 'qrcode.vue'
import VueQRCodeComponent from 'vue-qrcode-component'
export default {
components: { ContentLoader,VueQRCodeComponent },
name: 'QrCodeId',
data() {
  return {
    device_identification:null,
    loading:true,
    interval:null,
    displayBig:false,
    identified:false,
  }
},
async mounted(){

  let device=this.getExactAppleDeviceModel()

  if(device!="Modèle inconnu" && device!="Modèle autre")
  {
    this.$emit('selectDeviceEv', device)
    this.identified=true
    this.device_identification={device:device}
      clearInterval(this.interval)
      this.loading=false
    
    return
  }

  let params={
    status:"pending"
  }
  this.device_identification=  await APIRequest.create('device-identifications',params)

  this.interval=setInterval(this.checkDevice, 5000);

  this.loading=false

},
props:["language"],
methods:{
  getExactAppleDeviceModel() {
      const ua = navigator.userAgent;

      // Vérifie si c'est un appareil Apple (iPhone ou iPad)
      if (!/iPhone|iPad/.test(ua)) return "Modèle autre";

      // Obtenir la résolution de l'écran en points CSS
      const width = screen.width * window.devicePixelRatio;
      const height = screen.height * window.devicePixelRatio;

      // Liste des résolutions connues pour iPhone et iPad
      const devices = {
          "iPhone (1st gen), 3G, 3GS": { width: 320, height: 480 },
          "iPhone 4, 4S": { width: 640, height: 960 },
          "iPhone 5, 5C, 5S, SE (1st gen)": { width: 640, height: 1136 },
          "iPhone SE (2nd/3rd gen), iPhone 6/7/8": { width: 750, height: 1334 },
          "iPhone 6+/7+/8+": { width: 1080, height: 1920 },
          "iPhone X, XS, 11 Pro": { width: 1125, height: 2436 },
          "iPhone XR, 11": { width: 828, height: 1792 },
          "iPhone XS Max, 11 Pro Max": { width: 1242, height: 2688 },
          "iPhone 12 Mini, 13 Mini": { width: 1080, height: 2340 },
          "iPhone 12/12 Pro, 13/13 Pro, 14, 15": { width: 1170, height: 2532 },
          "iPhone 12 Pro Max, 13 Pro Max, 14 Plus, 15 Plus": { width: 1284, height: 2778 },
          "iPhone 14 Pro, 15 Pro, 16": { width: 1179, height: 2556 },
          "iPhone 14 Pro Max, 15 Pro Max, 16 Plus": { width: 1290, height: 2796 },
          "iPhone 16 Pro, 16 Pro Max": { width: 1320, height: 2868 }, // (hypothétique)
          "iPad Mini (1st-4th gen)": { width: 768, height: 1024 },
          "iPad Mini (5th gen), iPad Air (1st-2nd gen)": { width: 1536, height: 2048 },
          "iPad Mini (6th gen),": { width: 1488, height: 2266 },
          "iPad (7th-9th gen)": { width: 1620, height: 2160 },
          "iPad Air (4th-5th gen)": { width: 1640, height: 2360 },
          "iPad Pro 9.7\"": { width: 1536, height: 2048 },
          "iPad Pro 10.5\"": { width: 1668, height: 2224 },
          "iPad Pro 11\" (1st-4th gen)": { width: 1668, height: 2388 },
          "iPad Pro 12.9\" (3rd-6th gen)": { width: 2048, height: 2732 },
      };

      // Trouver le modèle correspondant à la résolution détectée
      for (const [model, res] of Object.entries(devices)) {
          if ((width === res.width && height === res.height) || (width === res.height && height === res.width)) {
              return model;
          }
      }

      return "Modèle d'iPhone inconnu";
  },
  async checkDevice()
  {
    
    let device_id = await APIRequest.detail('device-identifications',this.device_identification.id)
    if(device_id.status=="ok")
    {
      this.device_identification.device=device_id.device
      this.identified=true
      this.$emit('selectDeviceEv', device_id.device)
      clearInterval(this.interval)
    }
  },
  
},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.qrcode-id
{
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  width: fit-content;
}
.text
{
  text-align:center;
  font-size:16px;

}
.qr-container
{
  width:100%;
  text-align:center;
}
.qrcode-mini-text
{
  margin-left:44px;
}
.qrcode-mini
{
  position: absolute;
  top:50%;
  transform: translateY(-50%);
}
.button-white {
  background-color: white;
  color: #000;
  margin-bottom:12px;
  gap:12px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #000;
  padding: 10px 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-white:hover {
  background-color: #000;
  color: #fff;
}

@media screen and (min-width:760px) and (max-width:1300px) {

  .button-white
  {
    padding: 11px 14px !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width:760px) {

  .content-init .content-bottom .buttons .button-white
  {
    padding: 10px 14px !important;
    font-size: 11px !important;
  }
  .qrcode-mini
  {
    width:25px !important;
  }
}
</style>
