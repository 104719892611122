<template>
    <div id="cart-total" v-if="displayCartTotal">
        <content-loader v-if="loaded == false || cartTotalLoaded==false" viewBox="0 0 410 50" primaryColor="#f3f3f3" secondaryColor="#cccccc">
            <rect x="0" y="0" rx="25" ry="25" width="150" height="50" />
            <rect x="250" y="0" rx="25" ry="25" width="150" height="50" />
        </content-loader>
        <div v-else>
          
            <div class="purchase">
                <a  v-if="language=='fr'" class="button-purchase" @click="proceedCheckout()">
                    Commander
                    <span class="total_cart">
                        Total / &nbsp;&nbsp;&nbsp;<span id="total_cart">{{ totalAmount }}</span>€
                    </span>
                </a>
                <a  v-else class="button-purchase" @click="proceedCheckout()">
                    Checkout
                    <span class="total_cart">
                        Total / &nbsp;&nbsp;&nbsp;<span id="total_cart">{{ totalAmount }}</span>€
                    </span>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import payment_processors from '../../../data/cart/payment_processors';

export default {
    name: "CartTotal",
    props: ["displayCartTotal", "loaded", "cart", "onlycollab", "totalAmount","cartTotalLoaded","language"],
    data(){
        const {processors} = payment_processors
        return{
            processors 
        }
    },
    methods:{
        async proceedCheckout() {
            await this.$emit('proceedCheckoutEv')
        }
    }

}
</script>

<style scoped>
@media screen and (min-width:760px) and (max-width:1300px)
{
    #popup-cart #cart-total .total_cart
  {
    font-size: 14px !important;
  }
  #popup-cart #cart-total .purchase a
  {
    padding:12px 14px !important;
    font-size: 14px !important;
  }
}
.button-purchase {
    cursor: pointer;
}
#cart-total .label
{
  position: relative;
  top:10px;
}

.delivery
{
  font-size:11px;
  position:relative;
  top:-35px;
}

.card-icons 
{
  float:right;
}
.card-icons img
{
  margin-left:5px;
  width:49px;
  display: inline-block;
  vertical-align: top;
}

</style>