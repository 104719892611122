<template>
    <div class="left">
       
        <div class="content">
            


            <!-- START - We recommend to place the below code where you want the form in your website html  -->
            <div class="sib-form" style="text-align: center;
         background-color: transparent;">
                <div id="sib-form-container" class="sib-form-container">
                    <div  id="error-message" class="sib-form-message-panel"
                        style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:540px;">
                        <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                            <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
                                <path
                                    d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z">
                                </path>
                            </svg>
                            <span class="sib-form-message-panel__inner-text">
                                Nous n'avons pas pu confirmer votre inscription.
                            </span>
                        </div>
                    </div>
                    <div ></div>
                    <div  id="success-message" class="sib-form-message-panel"
                        style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66;max-width:540px;">
                        <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                            <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
                                <path
                                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z">
                                </path>
                            </svg>
                            <span class="sib-form-message-panel__inner-text">
                                Votre inscription est confirmée.
                            </span>
                        </div>
                    </div>
                    <div></div>
                    <div id="sib-container" class="sib-container--large sib-container--vertical"
                        style="text-align:center; background-color:transparent; max-width:540px; border-radius:3px; border-width:0px; border-color:#C0CCD9; border-style:solid;">
                        <form id="sib-form" method="POST"
                            action="https://3819e0b2.sibforms.com/serve/MUIFACLnu7mctPTqMDsevBq2y6EDmxINE9tb7ZyTboZGcT4VbR1zbP_MdxHmHVOcetC8tty-R7_hcA7NmYvPH8Rl9VnAc9gmii6EDF_PIirWD0tGXGjvUrF5Ya-q1W6CDZ0gqonhkdJeoqudRm-1axpVSFfhoZVaGwVY2vPPYxUVjSVc2BO8UAa8VOBdJGufs9zpOeK5idUGffdr" data-type="subscription" novalidate="true">
                            <div style="padding: 8px 0;">
                                <div class="sib-input sib-form-block">
                                    <div class="form__entry entry_block">
                                        <input class="input" @change="changeNewsletterEmail($event.target.value)"
                                            type="email" id="EMAIL2" name="EMAIL" autocomplete="off" placeholder="Email"
                                            data-required="true" required="">
                                        <label class="entry__error entry__error--primary"
                                            style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="sib-form-block" style="text-align: left">
                                    <button class="sib-form-block__button sib-form-block__button-with-loader"
                                        form="sib-form" type="submit">
                                        <svg
                                            class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"></svg>
                                    </button>
                                </div>
                            </div>

                            <input type="text" name="email_address_check" value="" class="input--hidden">
                            <input type="hidden" name="locale" value="fr">
                        </form>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "FooterNewsletter",
    props:["displayModalMenu",'language'],
    methods: {
        changeNewsletterEmail(value){
            this.$emit('changeNewsletterEmailEv',value)
        }
    }

}
</script>

<style scoped>
input[type="email"] {
 
    padding: 5px 0;

    border-bottom: 1px solid #fff;
    
    font-size: 14px;
   
    color: #fff;
}
.sib-form
{
    padding:0;
    position: relative;
    width:80%;
    max-width: 350px;
}
button
{
    background:transparent;
    position:absolute;
    right:0;
    bottom:20px;
}
button::after
{
    content: ' ';
    width:10px;
    height:15px;
    color:#fff;
    font-size: 20px;
    position: absolute;
    right: 0;
    background-size: 100% 100%;
    bottom: 0;
    background-image:url('../../../../public/assets/images/arrow_right_w.svg'); 
}
</style>