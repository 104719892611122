<template>
        <div :class="[eye === 'right' ? 'left' : 'right']">
            <h4>Oeil {{ eye === 'right' ? 'droit (OD)' : 'gauche (OG)'}}</h4>

            <label>Sphère</label>

            <select class="custom-select slct-sph" :value="0.00" v-on:change="sphereChange(eye, $event)">
            <!-- <select class="custom-select slct-sph" :value="0.00" @input="sphereChange(eye, $event)"> -->
                <template v-for="(sphere, index) in spheres" :key="index">
                    <option :class="`shpere-${index}-${eye}`"  :value="sphere">{{ sphere.toFixed(2) }}</option>
                </template>
            </select>


            <label>Cylindre</label>

            <select class="custom-select slct-cyl" :value="0.00" v-on:change="cylinderChange(eye, $event)">
                <template v-for="(cyl, index) in cylinder" :key="index">
                    <option :class="`cylinder-${index}-${eye}`" :value="cyl">{{ cyl.toFixed(2) }}</option>
                </template>
            </select>


            <label>Axe</label>

            <select class="custom-select slct-axis" :value="0.00" v-on:change="axisChange(eye, $event)">
                <template v-for="(ax, index) in axis" :key="index">
                    <option :class="`axis-${index}-${eye}`" :value="ax">{{ ax }}</option>
                </template>
            </select>


            <h4 class="pupillary">Ecart pupillaire</h4>

            <label>Optionnel</label>

            <div class="checkbox" v-bind:class="{ active: form.full_pupillary_distance == true }"
                @click="checkItem('full_pupillary_distance')">

                <label>Ecart pupillaire total</label>

            </div>

            <label>Ecart pupillaire</label>

            <select class="custom-select slct-spd" v-if="form.semi_pupillary_distance == true" v-on:change="spdistChange(eye, $event)">
                <template v-for="(spd, index) in semi_pupillary_distance" :key="index">
                    <option :class="`spd-${index}-${eye}`" :value="spd">{{spd.toFixed(2)}}</option>
                </template>
            </select>
            
            <select class="custom-select slct-pdist" v-else-if="eye === 'right'" v-on:change="pdistChange($event)">
                <template v-for="(pdist, index) in pupillary_distance" :key="index">
                    <option :class="`pdist-${index}`" :value="pdist">{{pdist.toFixed(2)}}</option>
                </template>
            </select>
            
            
            
            <!--<div class="checkbox" v-bind:class="{active:form.prism==true}" @click="checkItem('prism')"> <label>Prisme</label></div>-->
            

            <select class="custom-select slct-ppos" v-if="form.prism == true" v-on:change="prismPosChange(eye, $event)">
                <template v-for="(prism, index) in prismPos" :key="index">
                    <option :class="`prismPos-${index}-${eye}`" :value="prism.value">{{prism.angle}}{{ prism.desc }}</option>
                </template>
            </select>
            
            
            <label v-if="form.prism == true">Addition</label>

            <select class="custom-select slct-pstr" v-if="form.prism == true" v-on:change="prismStrChange(eye, $event)">
                <option value="-">-</option>
                <template v-for="(prism, index) in prism_strength" :key="index">
                    <option :class="`prismStr-${index}-${eye}`" :value="prism">{{prism.toFixed(2)}}</option>
                </template>
            </select>

        </div>

</template>

<script>
// Import Data Arrays
import axis from "../../../data/popup/prescriptions/axis"
import cylinder from "../../../data/popup/prescriptions/cylinder"
import prism_position from "../../../data/popup/prescriptions/prism_position"
import prism_strength from "../../../data/popup/prescriptions/prism_strength"
import pupillary_distance from "../../../data/popup/prescriptions/pupillary_distance"
import semi_pupillary_distance from "../../../data/popup/prescriptions/semi_pupillary_distance"
import spheres from "../../../data/popup/prescriptions/spheres"

export default {
    name: "OptionCustom",
    props: ["eye", "step", "currentOption", "form"],
    methods: {
        sphereChange(eye, e){
            this.$emit('sphereChangeEv', eye, e.target.value)
        },
        cylinderChange(eye, e){
            this.$emit('cylinderChangeEv', eye, e.target.value)
        },
        axisChange(eye, e){
            this.$emit('axisChangeEv', eye, e.target.value)
        },
        spdistChange(eye, e){
            this.$emit('spdistChangeEv', eye, e.target.value)
        },
        pdistChange(e){
            this.$emit('pdistChangeEv', e.target.value)
        },
        prismPosChange(eye, e){
            this.$emit('prismPosChangeEv', eye, e.target.value)
        },
        prismStrChange(eye, e){
            this.$emit('prismStrChangeEv', eye, e.target.value)
        },
        checkItem(str){
            this.$emit('checkItemEv', str)
        },
    },
    data() {
        const {prismPos} = prism_position
        return {
            axis,
            cylinder,
            prismPos,
            prism_strength,
            pupillary_distance,
            semi_pupillary_distance,
            spheres,
        }
    }
}
</script>

<style scoped>
#popup-prescription .content .left {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  padding-right: 10px;
}

#popup-prescription .content .right
    {
      
      display: inline-block;
      width:49%;
      vertical-align: top;
      padding-left:10px;
      text-align: left;
    }
#popup-prescription #cross {
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  left: 10px;
  right: auto;
  top: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

#popup-prescription .prescription-option:hover {
  background: rgba(0, 0, 0, 0.1);
}

#popup-prescription .prescription-option {
  background: #f1f1f1;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.5s;
}

#popup-prescription label {
  font-size: 12px;
}

#popup-prescription h4.pupillary {
  margin-bottom: 0;
}

#popup-prescription h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#popup-prescription h2,
#popup-prescription h3 {
  font-size: 23px;
  margin-top: 0;
  margin-bottom: 0;
}

#popup-prescription input,
#popup-prescription select {
  padding: 10px 20px;
  font-size: 12px;
  width: 100%;
  margin-bottom: 10px;
}

div.checkbox.active::before {
  background: #000;
}

div.checkbox::before {
  content: ' ';
  position: absolute;
  left: 0;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 1px solid black;
}

div.checkbox {
  position: relative;
  padding-left: 20px;
}

</style>