import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound.vue'


const routes = [
  // {
  //   path: '/home',
  //   name: 'Home',
  //   title:'VISAGES - Home',
  //   component: Home
  // },
  
  // {
  //   path: '/:lang([a-z]{2})?/stockist',
  //   name: 'stockist',
  //   component: () => import('../views/Stockists.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/nos-valeurs',
  //   name: 'our-values',
  //   component: () => import('../views/OurValues.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/stockist/:id(\\d+)',
  //   name: 'stockist-detail',
  //   component: () => import('../views/Stockist.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/brand/:brand',
    name: 'brand',
    component: () => import('../views/Brand.vue')
  },
  // {
  //   path: '/:lang([a-z]{2})?/review/:id/:review(\\d+)?',
  //   name: 'review',
  //   component: () => import('../views/Review.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/comment-ca-marche',
    name: 'howdoesitworks',
    component: () => import('../views/HowDoesItWorksNew.vue')
  },
  // {
  //   path: '/:lang([a-z]{2})?/concept',
  //   name: 'concept',
  //   component: () => import('../views/Concept.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/opticien-connecte',
  //   name: 'opticien-connecte',
  //   component: () => import('../views/Opticien.vue')
  // },

  // {
  //   path: '/:lang([a-z]{2})?/remboursement-mutuelle',
  //   name: 'mutual-refund',
  //   component: () => import('../views/MutualRefund.vue')
  // },

  // {
  //   path: '/:lang([a-z]{2})?/telechargement-app',
  //   name: 'download',
  //   component: () => import('../views/Download.vue')
  // },

  // {
  //   path: '/:lang([a-z]{2})?/collaboration/:id(\\d+)',
  //   name: 'collaboration-glass',
  //   component: () => import('../views/CollaborationDetail.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/accessoire/:id(\\d+)',
  //   name: 'accessorie',
  //   component: () => import('../views/AccessorieDetail.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/collection/:id/:favorite(\\d+)?',
    name: 'collection-glass',
    component: () => import('../views/CollectionGlass.vue')
  },
  {
    path: '/:lang([a-z]{2})?/collection/:id/color/:color(\\d+)/:tint(\\d+)?',
    name: 'collection-glass-color',
    component: () => import('../views/CollectionGlass.vue')
  },
  {
    path: '/:lang([a-z]{2})?/collection/:id/type/:type/:color(\\d+)?',
    name: 'collection-glass-color-type',
    component: () => import('../views/CollectionGlass.vue')
  },
  
  // {
  //   path: '/:lang([a-z]{2})?/reviews/:id(\\d+)',
  //   name: 'reviews',
  //   component: () => import('../views/Reviews.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/likes/:toggle?',
    name: 'likes',
    component: () => import('../views/Likes.vue')
  },
  {
    path: '/:lang([a-z]{2})?/scan/:id(\\d+)',
    name: 'scan-detail',
    component: () => import('../views/ScanDetail.vue')
  },
  {
    path: '/:lang([a-z]{2})?/scans',
    name: 'scans',
    component: () => import('../views/Scans.vue')
  },
  {
    path: '/:lang([a-z]{2})?/mon-compte/:section?',
    name: 'account',
    component: () => import('../views/Account.vue')
  },
  // {
  //   // path: '/nos-collaborations-new',
  //   path: '/:lang([a-z]{2})?/nos-collaborations-new/:id(\\d+)',
  //   name: 'collaboration-new',
  //   component: () => import('../views/CollaborationDetail.vue')
  //   // component: () => import('../views/CollaborationNew.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/collaborations',
  //   name: 'collaborations',
  //   component: () => import('../views/Collaborations.vue')
  // },
  // {
  //   // path: '/nos-collaborations',
  //   path: '/:lang([a-z]{2})?/nos-collaborations/:id(\\d+)',
  //   name: 'collaboration',
  //   component: () => import('../views/CollaborationDetail.vue')
  //   // component: () => import('../views/Collaboration.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  // {
  //   path: '/:lang([a-z]{2})?/livraisons-suivis-colis-retours',
  //   name: 'delivery',
  //   component: () => import('../views/Delivery.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/cgv',
    name: 'cgv',
    component: () => import('../views/Cgv.vue')
  },

  // {
  //   path: '/:lang([a-z]{2})?/collection-meyrowitz/:id(\\d+)',
  //   name: 'collection-glass-meyrowitz',
  //   component: () => import('../views/CollectionGlassMeyro.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/meyrowitz',
  //   name: 'meyro',
  //   component: () => import('../views/Meyro.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/redirect-app',
    name: 'redirect-app',
    component: () => import('../views/RedirectApp.vue')
  },
  {
    path: '/:lang([a-z]{2})?/payment-confirmation/:id(\\d+)',
    name: 'payment-confirmation',
    component: () => import('../views/PaymentConfirmation.vue')
  },
  {
    path: '/:lang([a-z]{2})?/payment-validation/:id(\\d+)',
    name: 'payment-validation',
    component: () => import('../views/PaymentValidation.vue')
  },
  {
    path: '/:lang([a-z]{2})?/reset-password/:token',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/:lang([a-z]{2})?/detail-commande/:id(\\d+)',
    name: 'order-detail',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/:lang([a-z]{2})?/identify-device/:id',
    name: 'identify-device',
    component: () => import('../views/Identify.vue')
  },
  {
    path: '/:lang([a-z]{2})?/get-scan/:id',
    name: 'get-scan',
    component: () => import('../views/GetScan.vue')
  },
  {
    path: '/:lang([a-z]{2})?/faq',
    name: 'faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/:lang([a-z]{2})?/politique-de-confidentialite',
    name: 'confidentiality',
    component: () => import('../views/Confidentiality.vue')
  },
  {
    path: '/:lang([a-z]{2})?/paypal-app/:id(\\d+)',
    name: 'paypal-app',
    component: () => import('../views/PaypalApp.vue')

  },
  {
    path: '/:lang([a-z]{2})?/checkout-app/:id(\\d+)',
    name: 'checkout-app-id',
    component: () => import('../views/app/CheckoutApp.vue')
  },
  {
    path: '/:lang([a-z]{2})?/checkout-app/:id(\\d+)/:token',
    name: 'checkout-app',
    component: () => import('../views/app/CheckoutApp.vue')
  },
  {
    path: '/:lang([a-z]{2})?/collection-beta/',
    name: 'collection-beta',
    component: () => import('../views/CollectionBeta.vue')
  },
  {
    path: '/:lang([a-z]{2})?/collection-beta-new/',
    name: 'collection-beta-new',
    component: () => import('../views/CollectionBetaPage.vue')
  },
  // {
  //   path: '/:lang([a-z]{2})?/collections/:toggle?',
  //   name: 'collection',
  //   component: () => import('../views/Collection.vue')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/accessoires',
  //   name: 'accessories',
  //   component: () => import('../views/Accessories.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/add-card/:id(\\d+)',
    name: 'add-card',
    component: () => import('../views/app/AddCard.vue')
  },
  {
    path: '/:lang([a-z]{2})?/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue')
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('../components/Test')
  // },
  // {
  //   path: '/:lang([a-z]{2})?/about',
  //   name: 'About',
   
  //   component: () => import('../views/About.vue')
  // },
  {
    path: '/:lang([a-z]{2})?/',
    name: 'landing',
    component: () => import('../views/Landing.vue')
  },

  { path: '/:pathMatch(.*)*', component: PageNotFound },
  
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll 10px above the element #main
    return {
      // could also be
      // el: document.getElementById('main'),
      el: '#app',
      top: 0,
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  // scrollBehavior (to, from, savedPosition) {
})

export default router
