<template>
  <div id="popup-login" @click="closeModal($event)" class="popup-login login">

  
    <div class="content">
      <div class='btn-grey-small cross' @click="closeLogin()">
          <img width='14'
          src='../../../public/assets/images/close_small.svg' />
      </div>

      <div v-if="displayInit && step>1" class='btn-grey-small back-popup' @click="goBack()">
          <img width='9'
          src='../../../public/assets/images/arrow_left.svg' />
      </div>
     
      <content-loader v-if="loadingPopup==true" viewBox="0 0 520 230" primaryColor="#f3f3f3" secondaryColor="#cccccc">

        <rect x="0" y="0" rx="0" ry="0" width="230" height="230" />
        <rect x="320" y="20" rx="5" ry="5" width="150" height="10" />
        <rect x="320" y="60" rx="5" ry="5" width="150" height="10" />
        <rect x="320" y="100" rx="5" ry="5" width="150" height="10" />
        <rect x="320" y="140" rx="5" ry="5" width="150" height="10" />
        <rect x="320" y="180" rx="5" ry="5" width="150" height="10" />

      </content-loader>
      <div class="left" :class="{'white-bg':displayLoginForm || displayRegisterForm}" v-if="loadingPopup==false">
        
      
        <div class="p little" v-if="displayLoginForm">
          <template v-if="language=='fr'">
            Don’t have an account yet ? <a href="#" @click="displayLoginForm=false;displayRegisterForm=true">Sign up here</a>
          </template>
          <template v-else>
            Don’t have an account yet ? <a href="#" @click="displayLoginForm=false;displayRegisterForm=true">Sign up here</a>
          </template>
        </div>
        <p v-else-if="displayRegisterForm" class="p little" >
          <template v-if="language=='fr'">
            Already have an account? <a href="#" @click="displayLoginForm=true;displayRegisterForm=false">Log in here</a>
          </template>
          <template v-else>
            Already have an account?<a href="#" @click="displayLoginForm=true;displayRegisterForm=false">Log in here</a>
          </template>
        </p>
        <p v-else class="p" >
        <div >
          <span v-if="language=='fr'">Scan déjà effectué ? Connectez-vous à votre compte</span>
            <span v-else>Scan already done? Log in to your account.</span>
            <br />
            <a class="btn" v-if="language=='fr'" @click="displayLoginForm=true;displayRegisterForm=false;" >Connexion</a>
            <a  class="btn" v-else @click="displayLoginForm=true;displayRegisterForm=false;" >Log in</a>
          </div>
          </p>
      </div>
      <div class="right"  v-if="loadingPopup==false">
        <template v-if="displayLoginForm || displayRegisterForm">
            
             

            <div class="title" v-if="displayLoginForm">
              <span v-if="language=='fr'">Connectez-vous à votre compte</span>
              <span v-else>Log in to your account</span>
            </div>
            <div class="title" v-if="displayRegisterForm">
              <span v-if="language=='fr'">Créez votre compte</span>
              <span v-else>Create your account</span>
            </div>
            
            <div class="login-form" v-if="displayLoginForm">
              
              <div class="input-line">
                <input type="text" v-if="language=='fr'" :class="{error:errors_login.username}"  id="login" v-model="username" placeholder="Nom d'utilisateur" />
                <input type="text" v-else :class="{error:errors_login.username}"  id="login" v-model="username" placeholder="Username" />
                <div v-if="errors_login.username" class="error-message">
                    {{errors_login.username}}
                </div>  
              </div>
              <div class="input-block">
                  <div class="input-line">
                    <input type="password" :class="{error:errors_login.password}" id="password" v-model="password" placeholder="******" />
                    <div v-if="errors_login.password" class="error-message">
                        {{errors_login.password}}
                    </div>  
                  </div>
                </div>
              <button v-if="language=='fr'" id="login_button" @click="postLogin()">Connexion</button>
              <button v-else id="login_button" @click="postLogin()">Log in</button>

              <a class="forgot" @click="forgotPassword()">Mot de passe oublié ?</a>
              <div id="error-message">
                <br />
                <center>{{errorLogin}}</center>

              </div>
              
              
            </div>
            <div class="register-form" v-if="displayRegisterForm">
              <div >
                
                <div class="input-line">
                  <input  v-if="language=='fr'" type="text" :class="{error:errors_register.surname}"  id="name_register" v-model="surname" placeholder="Prénom" />
                  <input  v-else type="text" :class="{error:errors_register.surname}"  id="name_register" v-model="surname" placeholder="Surname" />
                  <div v-if="errors_register.surname" class="error-message">
                      {{errors_register.surname}}
                  </div>
                </div>
                <div class="input-line">
                  <input  v-if="language=='fr'" type="text"  :class="{error:errors_register.name}"  id="surname_register" v-model="name" placeholder="Nom" />
                  <input  v-else type="text"  :class="{error:errors_register.name}"  id="surname_register" v-model="name" placeholder="Name" />
                  <div v-if="errors_register.name" class="error-message">
                      {{errors_register.name}}
                  </div>
                </div>
                <div class="input-line">
                  <input  type="text" :class="{error:errors_register.email}"  id="email_register" v-model="email" placeholder="Email" />
                  <div v-if="errors_register.email" class="error-message">
                      {{errors_register.email}}
                  </div>
                </div>
                <div class="input-line">
                  <input  v-if="language=='fr'" type="password" :class="{error:errors_register.password}"  id="password_register" v-model="password_register" placeholder="Mot de passe" />
                  <input v-else type="password" :class="{error:errors_register.password}"  id="password_register" v-model="password_register" placeholder="Password" />
                  
                  <div v-if="errors_register.password" class="error-message">
                      {{errors_register.password}}
                  </div>
                </div>
                <div class="input-line">
                  <input  v-if="language=='fr'" type="password" :class="{error:errors_register.password_repeat}"  id="password_register" v-model="password_repeat" placeholder="Répétez mot de passe" />
                  <input v-else type="password" :class="{error:errors_register.password_repeat}"  id="password_register" v-model="password_repeat" placeholder="Repeat password" />
                 <div v-if="errors_register.password_repeat" class="error-message">
                      {{errors_register.password_repeat}}
                  </div>
                </div>
                <button  id="register_button" @click="registerUser()" v-if="language=='fr'">Inscription</button>
                <button  id="register_button" @click="registerUser()" v-else>Register</button>
                <div id="error-message-register">
                  <br />
                <center>{{errorRegister}}</center>
                </div>

              </div>
              
              <!-- <div id="success-message" class="success-popup-login" v-if="success">
                <h2>Compte créé avec succès</h2>
                <b>01.</b><br />
                <b>Télécharger l’application mobile iOS.</b><br />
                Cliquez-ici pour télécharger l’application mobile IOS Senseye. Pour utiliser l’application et bénéficier d’un sur-mesure, vous devez avoir un iPhone X ou modèle plus récent.
                <br /><br />
                <b>02.</b><br />
                <b>Vos identifiants sur l’application mobile et le site internet sont les mêmes.</b><br />
                Après avoir scan votre visage, vous pourrez récupérer votre panier directement sur l’application mobile, ou en revenant sur le site internet, à votre convenance.
                
                <div class="buttons">
                  <button class="continue" @click="closeLogin()">Continuer sur le site</button>
                  <button class="download">Télécharger</button>
                </div>
              </div> -->
            </div>
        </template>
        <template v-else-if="displayInit">
          <div class="bar">
            <div class="bar-content" :style="'width:'+getCurrentBarWidth()+'%;'"></div>
          </div>
          <div class="content-init" v-if="step==1">
            <!-- <div v-if="accountNoScan" class="content-top">
              <span v-if="language=='fr'">VOTRE COMPTE NE DISPOSE PAS DE SCAN</span>
              <span v-else>NO SCAN FOUND IN YOUR ACCOUNT</span>
            </div> -->
            
            <div class="content-bottom">
             
              <div class="question" v-if="language=='fr'">
                Avez-vous un iPhone ou iPad équipé de Face ID ?<br />
              <span class="grey">Un scan sera requis après le paiement.</span>
              </div>
              <div class="question" v-else>
                Do you have an iPhone or iPad with Face ID?<br />
                <span class="grey">A face scan is needed after payment.</span>
              </div>
              <!-- <div class="text" v-if="language=='fr'">Le sur-mesure nécessite un scan du visage après paiement</div>
              <div class="text" v-else>Custom-Fit requires a facial scan after check-out</div> -->
              <div class="buttons" v-if="language=='fr'">
                <div class="button-white" @click="step=5">
                  Oui
                </div>
                <div class="button-white" @click="step=3">
                  Non
                </div>
                <div class="button-white" @click="step=8">
                  Identifier mon appareil
                </div>
              </div>
              <div class="buttons" v-else>
                <div class="button-white" @click="step=5">
                  Yes
                </div>
                <div class="button-white" @click="step=3">
                  No
                </div>
                <div class="button-white" @click="displayPopupDevices=true">
                  Identify my device
                </div>
              </div>

              
            </div>
          </div>
          <div class="content-init" v-if="step==8">
            
            <div class="content-bottom">
              <div class="question"  v-if="language=='fr'">
               
                
                <span class="grey">Notre scan facial nécessite un appareil Apple avec Face ID.</span> Quel appareil avez-vous ?

                <div class="infos-icon">
                    <img src="../../../public/assets/images/infos.svg" width="20" />

                    <div class="infos-popup">
                      <div class="popup-step">
                        <div class="number">1</div>
                        <div class="slash">/</div>
                        <div class="instructions">Open the Settings app on your iPhone or iPad.</div>
                      </div>
                      <div class="popup-step">
                        <div class="number">2</div>
                        <div class="slash">/</div>
                        <div class="instructions">Go to the General section.</div>
                      </div>
                      <div class="popup-step">
                        <div class="number">3</div>
                        <div class="slash">/</div>
                        <div class="instructions">Tap on Information.</div>
                      </div>
                      <div class="popup-step">
                        <div class="number">4</div>
                        <div class="slash">/</div>
                        <div class="instructions">You will find the 'Model Name' clearly displayed here.</div>
                      </div>
                    </div>
                
                
                </div>
              </div>

              <div class="question"  v-else>
                <span class="grey">Our face scan requires an Apple device with Face ID.</span> What device do you have?
                <div class="infos-icon">
                    <img src="../../../public/assets/images/infos.svg" width="20" />

                    <div class="infos-popup">
                      <div class="popup-step">
                        <div class="number">1</div>
                        <div class="slash">/</div>
                        <div class="instructions">Open the Settings app on your iPhone or iPad.</div>
                      </div>
                      <div class="popup-step">
                        <div class="number">2</div>
                        <div class="slash">/</div>
                        <div class="instructions">Go to the General section.</div>
                      </div>
                      <div class="popup-step">
                        <div class="number">3</div>
                        <div class="slash">/</div>
                        <div class="instructions">Tap on Information.</div>
                      </div>
                      <div class="popup-step">
                        <div class="number">4</div>
                        <div class="slash">/</div>
                        <div class="instructions">You will find the 'Model Name' clearly displayed here.</div>
                      </div>
                    </div>
                
                
                </div>
               
              </div>

             

              <div v-if="manualSelect==false">

                <div class="buttons">
                  <QrCodeId :language="language" @selectDeviceEv="selectDeviceQr" />

                  <div class="button-white active-black"  v-if="deviceSelected" @click="confirmDevice">
                    <span v-if="language=='fr'">Confirmer</span>
                    <span v-else>Confirm</span>
                  </div>

                  <div class="button-white" @click="manualSelect=true"  >
                    <span v-if="language=='fr'">Renseigner manuellement</span>
                    <span v-else>Enter manually</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="buttons"  v-if="language=='fr'">

                  <div class="button-white" :class="{disabled:selectiPhoneDevice==false,activated:selectiPhoneDevice,open:listiPhonesDisplayed}" @click="iPhoneSelection()">
                    <span>iPhone</span>
                    <span class="grey" v-if="selectiPhoneDevice">
                      <span v-if="selectedIphone==null">Selectionnez votre appareil</span>
                    <span v-else>{{selectedIphone}}</span>
                  </span>
                  <img src="../../../public/assets/images/arrow_select.svg" class="arrow-select" v-if="selectiPhoneDevice" />
                  <div class="list-button" v-if="listiPhonesDisplayed">
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone X')">iPhone X</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XR')">iPhone XR</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XS')">iPhone XS</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XS Max')">iPhone XS Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11')">iPhone 11</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro')">iPhone 11 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro Max')">iPhone 11 Pro Max</div>

                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12')">iPhone 12</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Mini')">iPhone 12 Mini</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro')">iPhone 12 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro Max')">iPhone 12 Pro Max</div>

                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13')">iPhone 13</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Mini')">iPhone 13 Mini</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro')">iPhone 13 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro Max')">iPhone 13 Pro Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14')">iPhone 14</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Plus')">iPhone 14 Plus</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro')">iPhone 14 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro Max')">iPhone 14 Pro Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15')">iPhone 15</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Plus')">iPhone 15 Plus</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro')">iPhone 15 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro Max')">iPhone 15 Pro Max</div>
                    </div>
                  </div>

                  <div class="button-white"  :class="{disabled:selectiPadDevice==false,activated:selectiPadDevice,open:listiPadsDisplayed}" @click="iPadSelection()">
                    <span>iPad</span>
                    <span class="grey" v-if="selectiPadDevice">
                      <span v-if="selectedIpad==null">Select your device</span>
                      <span v-else>{{selectedIpad}}</span>
                    </span>
                    <img src="../../../public/assets/images/arrow_select.svg" class="arrow-select" v-if="selectiPadDevice" />
                    <div class="list-button" v-if="listiPadsDisplayed">
                      <div class="elem-list" @click.prevent="selectiPad('iPad Pro 11p')">iPad Pro 11p</div>
                      <div class="elem-list" @click.prevent="selectiPad('iPad Pro 12.9p')">iPad Pro 12.9p</div>
                    
                    </div>
                  </div>
                  
                  

                  <div v-if="(selectiPadDevice && selectedIpad!=null) || (selectiPhoneDevice && selectedIphone!=null) || deviceSelected" class="button-white active-black" @click="deviceHasFaceID ? step=9 : step=10">
                  Confirmer
                  </div>
                  <div v-else class="button-white" @click="step=10">
                  Aucun des deux
                  </div>
                  
                </div>

                <div class="buttons" v-else>
              
                  <div class="button-white" :class="{disabled:selectiPhoneDevice==false,activated:selectiPhoneDevice,open:listiPhonesDisplayed}" @click="iPhoneSelection()">
                    <span>iPhone</span>
                    <span class="grey" v-if="selectiPhoneDevice">
                      <span v-if="selectedIphone==null">Select your device</span>
                    <span v-else>{{selectedIphone}}</span>
                  </span>
                  <img src="../../../public/assets/images/arrow_select.svg" class="arrow-select" v-if="selectiPhoneDevice" />
                  <div class="list-button" v-if="listiPhonesDisplayed">
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone X')">iPhone X</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XR')">iPhone XR</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XS')">iPhone XS</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XS Max')">iPhone XS Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11')">iPhone 11</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro')">iPhone 11 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro Max')">iPhone 11 Pro Max</div>

                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12')">iPhone 12</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Mini')">iPhone 12 Mini</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro')">iPhone 12 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro Max')">iPhone 12 Pro Max</div>

                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13')">iPhone 13</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Mini')">iPhone 13 Mini</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro')">iPhone 13 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro Max')">iPhone 13 Pro Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14')">iPhone 14</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Plus')">iPhone 14 Plus</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro')">iPhone 14 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro Max')">iPhone 14 Pro Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15')">iPhone 15</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Plus')">iPhone 15 Plus</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro')">iPhone 15 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro Max')">iPhone 15 Pro Max</div>
                    </div>
                  </div>

                  <div class="button-white"  :class="{disabled:selectiPadDevice==false,activated:selectiPadDevice,open:listiPadsDisplayed}" @click="iPadSelection()">
                    <span>iPad</span>
                    <span class="grey" v-if="selectiPadDevice">
                      <span v-if="selectedIpad==null">Select your device</span>
                      <span v-else>{{selectedIpad}}</span>
                    </span>
                    <img src="../../../public/assets/images/arrow_select.svg" class="arrow-select" v-if="selectiPadDevice" />
                    <div class="list-button" v-if="listiPadsDisplayed">
                      <div class="elem-list" @click.prevent="selectiPad('iPad Pro 11p')">iPad Pro 11p</div>
                      <div class="elem-list" @click.prevent="selectiPad('iPad Pro 12.9p')">iPad Pro 12.9p</div>
                    
                    </div>
                  </div>
                  <div v-if="(selectiPadDevice && selectedIpad!=null) || (selectiPhoneDevice && selectedIphone!=null)" class="button-white active-black" @click="step=9">
                  Confirm
                  </div>
                  <div v-else class="button-white" @click="step=10">
                    None of the above
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="content-init" v-if="step==9">
            
            <div class="content-bottom">
              <div class="question"  v-if="language=='fr'">
               
                Votre appareil dispose de Face ID ! <span class="grey">Complétez votre scan après le paiement</span>

              </div>

              <div class="question"  v-else>
                Your device has Face ID!<span class="grey">Complete your scan after payment.</span>
                
              </div>
             
              <div class="buttons"  v-if="language=='fr'">
                
                <div class="button-white active" @click="closeLoginScan()">
                  Continuer la personnalisation
                </div>
                
              </div>

              <div class="buttons" v-else>
                
                <div class="button-white active" @click="closeLoginScan()">
                  Continue customizing
                </div>
                
              </div>
            </div>
          </div>
          <div class="content-init" v-if="step==7">
            
            <div class="content-bottom">
              <div class="question"  v-if="language=='fr'">
               
                Super ! Après le paiement, empruntez un appareil <span class="grey">pour compléter votre scan.</span>

              </div>

              <div class="question"  v-else>
                Great! After payment, borrow a device <span class="grey">to complete your scan.</span>
                
              </div>
             
              <div class="buttons"  v-if="language=='fr'">
                
                <div class="button-white active" @click="closeLoginScan()">
                  Continuer la personnalisation
                </div>
                
              </div>

              <div class="buttons" v-else>
                
                <div class="button-white active" @click="closeLoginScan()">
                  Continue customizing
                </div>
                
              </div>
            </div>
          </div>
          
          <div class="content-init" v-if="step==6">
            
            <div class="content-bottom">
              <div class="question"  v-if="language=='fr'">
               
                Très bien ! Procédez à la sélection de l'option "Taille unique" <span class="grey">dans la personnalisation.</span>

              </div>

              <div class="question"  v-else>
                Alright! Proceed to select the “One-size” <span class="grey">option in customization.</span>
              </div>
             
              <div class="buttons"  v-if="language=='fr'">
                
                <div class="button-white active" @click="closeLoginUnique()">
                  Continuer la personnalisation
                </div>
                
              </div>

              <div class="buttons" v-else>
                
                <div class="button-white active" @click="closeLoginUnique()">
                  Continue customizing
                </div>
                
              </div>
            </div>
          </div>
          <div class="content-init" v-if="step==10">
            
            <div class="content-bottom">
              <div class="question"  v-if="language=='fr'">

                Votre appareil ne dispose pas de Face ID, <span class="grey">mais nous avons d'autres options. Laquelle vous convient le mieux ?</span>
              </div>

              <div class="question"  v-else>
               
                Your device does not have Face ID, <span class="grey">but we have other options. Which one works best for you?</span>
              </div>
             
            
              <div class="buttons"  v-if="language=='fr'">
                
                <div class="button-white" @click="step=6">
                  Acheter en taille unique
                  
                </div>
                <div class="button-white" @click="step=7">
                  Emprunter un appareil compatible après paiement
                </div>
           
                
              </div>

              <div class="buttons" v-else>
                <div class="button-white" @click="step=6">
                  Purchase 'One-size'
                </div>
                <div class="button-white" @click="step=7">
                  Borrow a compatible device after payment
                </div>
                
              </div>
            </div>
          </div>
          <div class="content-init" v-if="step==3">
            
            <div class="content-bottom">
              <div class="question"  v-if="language=='fr'">

                Nous avons d'autres options, <span class="grey">laquelle vous convient le mieux ?</span>
              </div>

              <div class="question"  v-else>
               
                We have other options, <span class="grey">which one works best for you?</span>
              </div>
             
            
              <div class="buttons"  v-if="language=='fr'">
                
                <div class="button-white" @click="step=6">
                  Acheter en taille unique
                  
                </div>
                <div class="button-white" @click="step=7">
                  Emprunter un appareil compatible après paiement
                </div>
           
                
              </div>

              <div class="buttons" v-else>
                <div class="button-white" @click="step=6">
                  Purchase 'One-size'
                </div>
                <div class="button-white" @click="step=7">
                  Borrow a compatible device after payment
                </div>
                
              </div>
            </div>
          </div>

          <div class="content-init" v-if="step==5">
            
            <div class="content-bottom">
               <div class="question" v-if="language=='fr'">
                Vous êtes prêt(e) pour le sur-mesure!<br />
                <span class="grey">Complétez votre scan après paiement</span>
              </div>
              <div class="question" v-else>
                You're ready for a custom fit!<br />
                <span class="grey">Complete your scan after payment</span>
              </div>
              
              <!-- <div class="text" v-if="language=='fr'"><span class="i">Finalisez votre commande</span> et vous recevrez des instructions détaillées pour réaliser votre scan.</div>
              <div class="text" v-else><span class="i">Complete your order</span> and you will receive detailed instructions for conducting your scan.</div> -->
              <div class="buttons" v-if="language=='fr'">
              
                <div class="button-white big active" @click="closeLoginScan()">
                  Continuer la personnalisation
                </div>
              </div>
              <div class="buttons" v-else>
              
              <div class="button-white big active" @click="closeLoginScan()">
                Continue customizing
              </div>
            </div>
            </div>
          </div>


          <div class="content-init" v-if="step==4">
           
            <div class="content-bottom">

              <div class="question" v-if="language=='fr'">
                <center><img src="../../../public/assets/images/pouce.svg" width="35" /></center>
                <br />
                SCAN TROUVÉ AVEC SUCCÈS
              </div>
              <div class="question" v-else>
                <center><img src="../../../public/assets/images/pouce.svg" width="35" /></center>
                <br />
                SCAN FOUND WITH SUCCESS
              </div>

            
  
              <div class="text"  v-if="language=='fr'">Votre compte dispose déjà d’un scan - Sélectionner le scan pour lequel vous souhaitez un ajustement personnalisé</div>
              <div class="text"  v-else>Your account already have a scan - Select the scan you want a custom-fit</div>
              <div class="buttons">
              
                <div class="button-white active big" @click="closeLogin()"  v-if="language=='fr'">
                  Sélection du scan
                </div>
                <div class="button-white active big" @click="closeLogin()"  v-else>
                  Select a scan
                </div>
              </div>
            </div>
          </div>
          <div class="content-init" v-if="step==2">
            <div class="title">LE SUR-MESURE NÉCESSITE UN SCAN DU VISAGE APRÈS PAIEMENT</div>
            <div class="content-bottom">
              <div class="question">À QUEL APPAREIL IOS AVEZ-VOUS ACCÈS ?<br />(<span class="blue">iPhone</span> ou <span class="blue">iPad</span>)</div>
              <div class="text">Notre technologie est disponible sur IOS, sur tous les appareils équipés de Face ID.</div>
              <div class="selects">
                <div class="select" :class="{active:selectedIphone!=null}" @click="listiPhonesDisplayed = !listiPhonesDisplayed,listiPadsDisplayed=false">
                  <div class="elem" v-if="selectedIphone==null">iPhones</div>
                  <div class="elem" v-else>{{selectedIphone}}</div>
                  <div class="list" v-if="listiPhonesDisplayed">
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone X')">iPhone X</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone XR')">iPhone XR</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone XS')">iPhone XS</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone XS Max')">iPhone XS Max</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 11')">iPhone 11</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro')">iPhone 11 Pro</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro Max')">iPhone 11 Pro Max</div>

                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 12')">iPhone 12</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Mini')">iPhone 12 Mini</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro')">iPhone 12 Pro</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro Max')">iPhone 12 Pro Max</div>

                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 13')">iPhone 13</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Mini')">iPhone 13 Mini</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro')">iPhone 13 Pro</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro Max')">iPhone 13 Pro Max</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 14')">iPhone 14</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Plus')">iPhone 14 Plus</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro')">iPhone 14 Pro</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro Max')">iPhone 14 Pro Max</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 15')">iPhone 15</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Plus')">iPhone 15 Plus</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro')">iPhone 15 Pro</div>
                    <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro Max')">iPhone 15 Pro Max</div>
                  </div>
                </div>

                <div class="select" :class="{active:selectedIpad!=null}" @click="listiPadsDisplayed = !listiPadsDisplayed,listiPhonesDisplayed=false">
                  <div class="elem"></div>
                  <div class="elem" v-if="selectedIpad==null">iPads</div>
                  <div class="elem" v-else>{{selectedIpad}}</div>
                  <div class="list" v-if="listiPadsDisplayed">
                    <div class="elem-list" @click.prevent="selectiPad('iPad Pro 11p')">iPad Pro 11p</div>
                    <div class="elem-list" @click.prevent="selectiPad('iPad Pro 12.9p')">iPad Pro 12.9p</div>
                  
                  </div>
                </div>
              </div>

              <div v-if="selectedIpad != null" class="text">Vous avez sélectionné l’appareil « {{selectedIpad}} » celui-ci est bien compatible au sur-mesure</div>
              <div v-if="selectedIphone != null" class="text">Vous avez sélectionné l’appareil « {{selectedIphone}} » celui-ci est bien compatible au sur-mesure</div>

              <div class="buttons">
                <div class="button-white"  @click="step=3">
                  Je ne vois pas mon appareil
                </div>

                <div  v-if="selectedIpad==null && selectedIphone==null" class="button-white active disabled" >
                  Continuer la personnalisation
                </div>
                <div  v-else class="button-white active" @click="closeLoginScan()">
                  Continuer la personnalisation
                </div>
              </div>
            </div>
          </div>
        </template>
        

      </div>
     
      
    </div>
  </div>
</template>


<script>
import {APIRequest} from '@simb2s/senseye-sdk';

import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader';
import QrCodeId from './QrCodeId.vue';

export default {
components: { ContentLoader,QrCodeId },
name: 'PopupLogin',
data() {
  return {
   
    manualSelect:false,
    selectiPhoneDevice:false,
    selectiPadDevice:false,
    displayIphones:false,
    displayIpads:false,
    accountNoScan:false,
    loadingPopup:true,
    displayPopupDevices:false,
  selectedIpad:null,
  selectedIphone:null,
   user:null,
   listiPhonesDisplayed:false,
   listiPadsDisplayed:false,
   username:'',
   password:'',
   password_register:'',
   password_repeat:'',
   name:'',
   surname:'',
   deviceHasFaceID:false,
   userScans:null,
   email:'',
   step:1,
   phone:'',
   errorLogin:'',
    errorRegister:'',
    success:false,
    email_forgot:'',
    errorReset:'',
    displayInit:true,
    displayLoginForm:false,
    displayRegisterForm:false,
    errors_login:{},
    errors_register:{}
  }
},
async mounted(){


  if(this.liking==true || this.review==true  || this.saving==true)
  {
    this.displayLoginForm=true
    
  }
  if(localStorage.id_user_senseye_auth)
  {
    await this.getUserScans()
    if(this.userScans && this.userScans.length)
    {
      
    }
    else 
    { 
    
      this.accountNoScan=true
    }

  }
  



  this.loadingPopup=false

},
props:['review', 'saving', 'customing','liking','likingId','language'],
methods:{
  confirmDevice(){
    console.log(this.deviceHasFaceID)
    if(this.deviceHasFaceID)
    {
      this.step=9
    }
    else {
      this.step=10
    }

  },
    hasFaceID(device) {
     
      if (!device || device === "Modèle autre") return false;

      // Liste des appareils avec Face ID
      const faceIDDevices = [
          "iPhone X", "iPhone XR", "iPhone XS", "iPhone XS Max",
          "iPhone 11", "iPhone 11 Pro", "iPhone 11 Pro Max",
          "iPhone 12 Mini", "iPhone 12", "iPhone 12 Pro", "iPhone 12 Pro Max",
          "iPhone 13 Mini", "iPhone 13", "iPhone 13 Pro", "iPhone 13 Pro Max",
          "iPhone 14", "iPhone 14 Plus", "iPhone 14 Pro", "iPhone 14 Pro Max",
          "iPhone 15", "iPhone 15 Plus", "iPhone 15 Pro", "iPhone 15 Pro Max",
          "iPhone 16", "iPhone 16 Plus", "iPhone 16 Pro", "iPhone 16 Pro Max",
          "iPad Pro 11\" (1st-4th gen)", "iPad Pro 12.9\" (3rd-6th gen)"
      ];

      // Vérifie si l'appareil est dans la liste
      
      for(let i=0;i<faceIDDevices.length;i++)
      {
        if(device.includes(faceIDDevices[i]))
        {
          return true
        }
      }
      return false;

     
  },
  selectDeviceQr(device)
  {

    this.deviceSelected=true
    this.selectedDevice=device
    this.deviceHasFaceID=this.hasFaceID(device)
    console.log('okkkk')
    this.$forceUpdate()
  },
  iPhoneSelection()
  {
    if(this.selectiPhoneDevice==true)
    {
      this.listiPhonesDisplayed=!this.listiPhonesDisplayed
    }
    else {
      this.selectiPadDevice=false
      this.selectiPhoneDevice=true
      this.listiPadsDisplayed=false
    }
    
  },
  iPadSelection()
  {
    if(this.selectiPadDevice==true)
    {
      this.listiPadsDisplayed=!this.listiPadsDisplayed
  
    }
    else
    {
      this.selectiPadDevice=true
      this.selectiPhoneDevice=false
      this.listiPhonesDisplayed=false
    }

  },
  getCurrentBarWidth()
  {
    if(this.step==7 || this.step==5 || this.step==2 || this.step==6 || this.step==9)
    {
      return 100
    }
    else if(this.step==3  || this.step==8)
    {
      return 50
    }
    else if(this.step==10)
    {
      return 75
    }
    else
    {
      return 25
    }
  },
  goBack()
  {
    if(this.step==7 || this.step==6)
    {
      this.manualSelect=false
      this.deviceSelected=false
      this.step=3
    }
    else
    {
      this.manualSelect=false
      this.deviceSelected=false
      this.step=1
      
    }
  },
  async userRegister (user) {
   
      if(user!=null)
      {
        this.loadingPopup=false
        this.user=user
        this.errorRegister='';
        
        await this.loginUser(this.email,this.password_register)

        if(this.saving==false && this.customing==false)
        {
          if(this.liking==true) {
            
            emitter.emit('reload-glasses-like', this.likingId)
          }
        }
        else {
          if(this.customing==true) {
            this.$emit('reloadUserContentEv')
            

            this.step=1
            
            
            this.displayRegisterForm=false
            this.displayLoginForm=false
            //this.closeLogin()
          }
          else if(this.liking==true) {
            
            emitter.emit('reload-glasses-like', this.likingId)
          }
          this.success=true
        }
      }
      else
      {
        this.loadingPopup=false
        this.errors_register={}
        this.errors_register.email="Votre e-mail contient une erreur où un compte avec cette adresse mail existe déjà."
        
      }

  },
  async userLogin (user) {

   
    if(user!==null)
    {

      if(this.saving==false && this.customing==false)
      {
        
        if(this.liking==true) {
          this.closeLogin()
          
          emitter.emit('reload-glasses-like', this.likingId)
        }
        else {
          //document.location.reload()
          await this.getUserScans()
          if(this.userScans && this.userScans.length)
          {
            this.step=4
          }
          else 
          { 
            this.step=1
            this.accountNoScan=true
          }
          
          this.displayRegisterForm=false
          this.displayLoginForm=false
        }
        
      }
      else
      {


      
        if(this.customing==true)
        {
          
          
        
          await this.getUserScans()
          if(this.userScans && this.userScans.length)
          {
            this.step=4
          }
          else 
          { 
            this.step=1
            this.accountNoScan=true
          }
          
          this.displayRegisterForm=false
          this.displayLoginForm=false
          //this.closeLogin()
        }
        else if(this.saving==true)
        {
          this.closeLogin()
          
          this.$emit('reloadUserContentSavingEv')
          
        }
        else if(this.liking==true) {
          this.closeLogin()
        
          emitter.emit('reload-glasses-like', this.likingId)
        }
        
      }
      this.loadingPopup=false
      
    }
    else {
      this.loadingPopup=false
      if(this.language=='fr')
      {
        this.errorLogin='Vos identifiants sont incorrects.';
      }
      else {
        this.errorLogin='Your credentials are not corrects.';
      }
      
    }

  },
  selectiPhone(iphone){
    this.selectedIphone=iphone
    this.selectedIpad=null
    this.deviceHasFaceID=this.hasFaceID(iphone)

  },
  selectiPad(iphone){
    this.selectedIphone=null
    this.selectedIpad=iphone
    this.deviceHasFaceID=this.hasFaceID(iphone)
  },
  closeLoginScan(){

    this.$emit('selectDevicePopupEv',this.customing)
    
    this.closeLogin()
  },
  closeLoginUnique(){
    this.$emit('selectUniqueSizePopup')
    
    this.closeLogin()
  },
  async postLogin()
  {
    this.loadingPopup=true
    this.errors_login={}
    if(this.username == "") {
      this.errors_login.username="Ce champs est obligatoire."
    }
    if(this.password == "") {
      this.errors_login.password="Ce champs est obligatoire."
    }
    if(Object.keys(this.errors_login).length==0) {
        await this.loginUser(this.username,this.password)
    }
    else {
      this.loadingPopup=false
    }

  },
    forgotPassword()
  {

    this.$emit('displayForgotPasswordEv')
    

  },
    closeLogin()
  {
    this.$emit('closePopupLoginEv')
  
  },
  async getUserScans()
  {
    let userScans=await APIRequest.list('scans',localStorage.id_user_senseye_auth,null,2)
    this.userScans=userScans[0];
  },
  closeModal($event)
  {

    if($event)
    {

   
   
      if (jQuery($event.target).parents('.content').length) {

      }
      else
      {
        if(jQuery($event.target).attributes.id=="login_button") {

        }
        else {
          if(jQuery($event.target).attributes.id=="register_button") {

        }
        else {
          
          this.$emit('closePopupLoginEv')
        }
          
        }
        
      }
    }
    else
    {
      this.$emit('closePopupLoginEv')
    }
  },
  async registerUser(){
    this.loadingPopup=true
    this.errors_register={}
    let errormandat
    let errorpass
    if(this.language=='fr'){
      errormandat="Ce champs est obligatoire."
      errorpass="Les deux mots de passe ne sont pas identiques."
    }
    else
    {
      errormandat="This field is mandatory."
      errorpass="The 2 passwords are not identical."
    }
    
    if(this.name == "") {
      this.errors_register.name=errormandat
    }
    if(this.password_register == "") {
      this.errors_register.password=errormandat
    }
    if(this.password_repeat == "") {
      this.errors_register.password_repeat=errormandat
    }
    if(this.password_register != this.password_repeat){
      this.errors_register.password_repeat=errorpass
      this.errors_register.password=errorpass
    }
    if(this.surname == "") {
      this.errors_register.surname=errormandat
    }
    if(this.email == "") {
      this.errors_register.email=errormandat
    }
    if(Object.keys(this.errors_register).length==0)
    {
      await emitter.emit('register',this.email,this.password_register,this.name,this.phone,this.surname,this.email)
    }
    else {
      this.loadingPopup=false
    }
    
  },
  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  },
  async loginUser(username,password)
  {
     
      await emitter.emit('login',username,password)
  },
  
},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width:760px) {
  #popup-login .content .left .p {
  font-size:13px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  margin:0 !important;
}
  #popup-login .content .left .p.little a,#popup-login .content .right a.forgot
  {
    font-size: 13px !important;
  }
  body #login_button,body #register_button
  {
    font-size: 13px !important;
    padding: 9px 14px !important;
  }
  #popup-login .content .right .login-form input,#popup-login .content .right .register-form input
  {
    padding: 9px 14px !important;
    font-size: 13px !important;
  }
  body .title
  {
    font-size: 20px !important;
  }
  .bar
  {
    left:10px !important;
    top:10px !important;
  }
  .list-button
  {
    top:36px !important;
  }
  .btn-grey-small.back-popup
  {
    top:10px;
    right:50px;
  }
  .btn-grey-small.cross
  {
    top:10px;
    right:10px;
  }
  .btn-grey-small
  {
    width:35px !important;
    height:35px !important;
  }
  #popup-login .content .left a.btn
  {
    margin-top:10px !important;
    font-size:13px !important;
    padding:11px 14px !important;
  }
  #popup-login .content {
  display: flex;
  flex-direction: column;
  width:90vw !important;
}
.arrow-select
{
  top:16px !important;
}
.content-init .content-bottom .buttons .button-white
{
  padding: 10px 14px !important;
  font-size: 11px !important;
}
.content-init .content-bottom .text-bold
{
  font-size:14px !important;
}
.content-init .content-bottom .buttons
{
  margin-bottom: 0px !important;
}
}

@media screen and (min-width:768px) and (max-width:1300px) {
  #popup-login .content .left .p.little,#popup-login .content .left .p.little a
  {
    font-size: 14px !important;
  }
  #popup-login .content .right a.forgot
  {
    font-size: 14px !important;
  }
  body #login_button,body #register_button
  {
    font-size: 14px !important;
    padding:11px 14px !important;
  }
  #popup-login .content .right .login-form input,#popup-login .content .right .register-form input
  {
    font-size: 14px !important;
    padding: 11px 14px !important;
  }

  #popup-login .content .left a.btn
  {
    
    font-size:14px !important;
    padding:14px 14px !important;
  }
  .content-init .content-bottom .buttons .button-white
  {
    font-size: 14px !important;
    padding:11px 14px !important;
  }
  #popup-login .content .left .p {
  font-size:14px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin:0 !important;
}
}

@media screen and (max-width:1024px) {
#popup-login .content .left::after {
  content:' ';
  position:absolute;
  top:-25px;
  height:1px;
  width:100%;
  z-index: 1;
  background:rgba(112, 112, 112, 0.3);
  
}
#popup-login .content .left::before {
  content:'ou';
  position:absolute;
  top:-35px;
  z-index: 2;
  padding-left:25px;
  padding-right:25px;
  background: #fff;
  color:rgba(112, 112, 112, 1);
  font-size: 14px;
}


#popup-login .content .left .gradient {
  order: 2;
  
  border-radius: 0px 0 10px 10px !important;
}
#popup-login .content .right {
  padding:10px;
}
#popup-login .content {
  padding:0 !important;
}
#popup-login .content {
  display: flex;
  flex-direction: column;
  width:80%;
}
.content-init .content-bottom .question {
  font-size: 21px !important;
}
.content-popup .list-item .num
{
  margin-left:0 !important;
  margin-right:10px !important;
}
.content-popup .list-item
{
  font-size: 12px !important;
}
.content-popup
 {
  width:90vw;
  max-width:90vw !important;
 }
.content-init .content-bottom .text,.dontknow
{
  font-size: 14px !important;
}
.content-init .title  {
  font-size: 12px !important;
  margin-top: 20px !important;
}
}
.back-popup
{
  position: absolute;
  right: 74px;
  top:20px;
  z-index:1;
  cursor: pointer;
}
.cross 
{
    position: absolute;
    right: 20px;
    top:20px;
    z-index:1;
    cursor: pointer;
}
.popup-step .slash
{
  
  margin: 0 10px;
}
.popup-step:last-child
{
  margin-bottom:0;
}
.popup-step 
{
  margin-bottom:10px;
  display: flex;
}
.infos-popup
{
  display:none;
  font-size:14px;
  padding:20px;
  position: absolute;
  top:30px;
  right:0;
  min-width: 300px;
  background:#F1F1F1;
  border:1px solid #999999;
  border-radius: 5px;
  z-index: 4;
}
.infos-icon:hover .infos-popup
{
  display:block;
}
.infos-icon
{
  display: inline-block;
  position: relative;
}
.btn-grey-small
{

  z-index:99;
    background:#F1F1F1;
    display: flex;
    cursor: pointer;
    width:40px;
    height:40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.open .arrow-select
{
  transform:rotate(180deg);
}
.arrow-select
{
  position:absolute;
  top:19px;
  right:10px;
  width:7px;
}
.selects .select.active {

background: transparent linear-gradient(94deg, #C0C0C0 0%, #0000FFE6 100%) 0% 0% no-repeat padding-box;
color:#fff;
}
.dontknow
{
display:inline-block;
clear: both;
font-size:15px;
cursor: pointer;
color:#000;
text-decoration: none;
border-bottom:1px solid black;
margin-bottom: 50px;
}
.selects .select::after {
width:18px;
height:18px;
top:8px;
}
.selects {
display: flex;
margin-top:20px;
justify-content: space-between;
}
.selects .select .list .elem-list:hover {
background: rgba(0, 0, 0, 0.3) 0% 0% no-repeat padding-box;
}
.selects .select .list .elem-list
{
transition:all 0.3s;
background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
backdrop-filter: blur(50px);
-webkit-backdrop-filter: blur(50px);
padding:9px;
color:#000;
border-top:1px solid #ccc;
}
.list-button .elem-list
{
  padding:11px 14px;
}
.list-button .elem-list:hover 
{
  background:#d9d9d9;
}
.list-button
{
  max-height: 160px;
  overflow-y: auto;
  position:absolute;
  width:calc(100% + 2px);
  top:42px;
  left:0;
  z-index:2;
  margin:-1px;
  background:#f1f1f1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-left:1px solid #000;
  border-right:1px solid #000;
  border-bottom:1px solid #000;
}
.selects .select .list{
position:absolute;
max-height: 300px;
overflow: auto;
left:0;
z-index:5000;
top:34px;
width:100%;
background: #fff;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 8px;
text-align: center;

}
.selects .select {
position:relative;
width:45%;
z-index:500;
background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 8px;
font-size:13px;
padding:9px;
text-align: center;
opacity: 1;
backdrop-filter: blur(50px);
-webkit-backdrop-filter: blur(50px);
}
.content-init .title {
font-size:18px;
margin-top:100px;
}
.content-init 
{

text-align: center;

}
.content-init .content-bottom .buttons .button-white .grey
{
  margin-left:15px;
  color:#999999;

}
.content-init .content-bottom .buttons .button-white.activated:hover {
  background: #f1f1f1;
border:1px solid #000;
color:#000;
display: flex;
align-items: center;
justify-content: flex-start;
}
.content-init .content-bottom .buttons .button-white.open {

  position: relative;
  background: #f1f1f1;
  border:1px solid #000;
  color:#000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.content-init .content-bottom .buttons .button-white.activated {

  position: relative;
  z-index: 3;
  background: #f1f1f1;
border:1px solid #000;
color:#000;

}
.content-init .content-bottom .buttons .button-white.disabled:hover {
background: transparent;
border:1px solid #000;
color:#000;
}
.content-init .content-bottom .buttons .button-white.disabled {

border:1px solid #999999;
color:#999999;
}
.content-init .content-bottom .buttons .button-white.gradient:hover {
background:#fff;
color:#000;
}
.content-init .content-bottom .buttons .button-white.active-black {
background:#000  !important;
border:1px solid #000 !important;
color:#fff  !important;
}
.content-init .content-bottom .buttons .button-white.active {
background:#63FF2B  !important;
border:1px solid #63FF2B !important;
color:#000  !important;
}
.content-init .content-bottom .buttons .button-white:hover {
background:#000;
color:#fff;
}
.content-init .content-bottom .buttons .button-white.gradient
{
border:0;
color:#fff;
background: transparent linear-gradient(90deg, rgba(192, 192, 192, 1) 0%, rgba(0, 0, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
}
.content-init .content-bottom .buttons .button-white
{
width:100%;
padding:10px 14px;
border:1px solid black;
transition: all 0.3s;
cursor: pointer;
text-align: left;
font-size:16px;
font-weight: 400;
border-radius: 5px;
margin-bottom:12px;
}
.content-init .content-bottom .buttons {
  display:flex;
  margin-top:30px;
  flex-direction: column;
  margin-bottom:20px;
  align-items: center;
  justify-content: space-between;
}
.question.u
{
display:inline-block;
clear: both;
margin-left:auto;
margin-right:auto;
border-bottom: 1px solid #000;
}
.content-init .content-bottom .text-bold
{
margin-top:20px;
font-weight: 600;
font-size:18px;
}
.content-init .content-bottom .buttons .button-white.big {
width:100%;
}
.or::before
{
content:' ';
position:absolute;
left:0;
top:50%;
height:1px;
width:calc(50% - 20px);
background:rgb(207, 207, 207);
}
.or::after
{
content:' ';
position:absolute;
right:0;
top:50%;
height:1px;
width:calc(50% - 20px);
background:rgb(207, 207, 207);
}
.or
{
position:relative;
color:rgba(112, 112, 112, 1);
font-size:15px;
line-height: 1;
text-align: center;
margin-top:20px;

}
.content-init .content-bottom .text .i
{
font-weight: 600;
font-style:italic;
}
.content-init .content-bottom .text
{
margin-top:20px;
font-size:18px;
}
.content-init .content-bottom .question .blue
{
color:#7979EA;
}
.content-init .content-bottom .question .grey
{
  color:#999999;
}
.content-init .content-bottom .question
{
  padding-left:0;
  padding-top:30px;
font-size:35px;
font-weight: 400;
text-align: left;
line-height: 1;
}
.content-init .content-bottom
{
  min-height:405px;
  display: flex;
  flex-direction: column;
padding-top:30px;
  justify-content: space-between;
}

.btn-white:hover {
color:#fff;
background-color: transparent;
}
.btn-white {
transition: all 0.3s;
cursor: pointer;
background-color:#fff;
white-space: nowrap;
color:#000;
border:1px solid #fff;
text-decoration: none;
font-size:13px;
font-weight: 600;
line-height: 0.7;
padding:10px 20px 10px 20px;
}
.popup-login
      {
          animation: fadeanimation 0.4s ease;
      }
      
input.error {
border:1px solid red !important;
}
.register-form input,.login-form input
{
margin-bottom:20px;
}
#popup-login .content .right .register-form input::placeholder,#popup-login .content .right .login-form input::placeholder
{

color:#717171;
}
#popup-login .content .right .login-form .input-block
{
position:relative;
}
#popup-login .content .right .register-form input,#popup-login .content .right .login-form input
{
font-size:16px;
position:relative;
border: 1px solid #000;
border-radius: 5px;
color:#000;
padding:10px 14px;
margin-bottom: 12px;
}
.popup .bg
{
position:absolute;
left:0;
top:0;
width:100vw;
height:100vh;
background:rgba(0,0,0,0.6);
z-index: 9999999999999999999999;
content:' ';
}
.content-popup .text.little
{
font-size:15px;
text-align: center;
}
.content-popup .list-item .num
{
width:25px;

padding-top:3px;
height:25px;
text-align: center;
font-size:15px;
border:1px solid black;
display:inline-block;
margin-left:20px;
border-radius: 50%;
margin-right:20px;
}
.content-popup .list-item
{
font-size:15px;
font-weight: 300;
line-height:1;
padding:10px 20px;
border-top:0.5px solid #c2c2c2;
}
.content-popup .list-items
{
display:flex;
flex-direction: column;
margin-bottom:20px;
background:#D1D1D6;
}
.content-popup .list-title
{
line-height: 1;
margin-top:10px;
margin-bottom:10px;
font-size:24px;
padding-left:50px;
padding-right:50px;
font-weight: 500;
text-align: center;
}
.content-popup .link
{
color:#000;
}
.content-popup .text 
{
font-size:18px;
text-align: center;
padding-left:50px;
padding-right:50px;
}
.content-popup
{
position:fixed;
left:50%;
top:50%;
z-index:99999999999999999999999;
background:#fff;
transform: translate(-50%,-50%);
min-width: 280px;
max-width:700px;
padding-top:50px;
padding-bottom:50px;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 10px;
}
.popup
{
position:fixed;
z-index:99;
left:0;
top:0;
}
.content-top
{
font-size:18px;
font-weight: 600;
text-align: center;
padding-bottom:30px;
padding-top:30px;
border-bottom:1px solid rgba(0,0,0,0.1);
}
#cross
{
position: absolute;

  cursor: pointer;
  border-radius: 50%;
  padding: 7px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}
#popup-login .content .right .footer
{
position:relative;
left:50%;
transform:translateX(-50%);
display:inline-block;
margin-top:20px;
margin-bottom:20px;
border-bottom:1px solid #c0c0c0;
}
#popup-login .content .right .choice-login-form.active
{
border-bottom:2px solid black;
font-weight: 600;
}
#popup-login .content .right button:hover
{
background-color:#fff;
}
#login_button,#register_button {
border-radius: 5px !important;
font-size:16px !important;
font-weight: 400 !important;
}
#popup-login .content .right button
{
width:100%;
font-size:15px;
background-color:#000;
}
input.error 
{
  margin-bottom: 20px !important;
}
#popup-login .content .right a.forgot
{
  display: block;
  width: fit-content;
font-size:16px;
color:#999;
position:relative;
margin-left:14px;
text-decoration: none;
margin-top:14px;
  border-bottom: 1px solid #999;
cursor: pointer;
}
#popup-login .content .right .choice-login-form
{
font-size:18px;
text-transform: uppercase;
cursor:pointer;
color:#000;
text-decoration: none;
padding-left:10px;
padding-right:10px;
}

#popup-login .content .left a 
{
font-size:15px;
cursor: pointer;
border-bottom:1px solid #fff;
text-decoration: none;
color:#fff;
}
#popup-login .content .left a.btn
{
  color:#fff;
  border:0;
  font-size:16px;
  line-height: 1;
  text-decoration: none;
  background-color: #000;
  border-radius: 5px;
  padding:13px 14px;
  width: 100%;
  margin-top:5px;
  display: block;
}
#popup-login .content .left .p
{
  padding-top:20px;
font-size:22px;
padding-left:20px;
width:100%;
line-height: 1.2;
z-index: 5;
text-align: left;
padding-right:20px;
position:relative;
color:#000;
font-weight: 400;
}
#popup-login .content .left .p .underline
{
border-bottom:1px solid black;
}
#popup-login .content .left .gradient
{
position:absolute;
left:0;
opacity:0.8;
width:100%;
height:100%;
top:0;
border-radius: 10px 0px 0px 10px;
background: transparent linear-gradient(180deg, #5D2BFF -150%, #0000E0 80%) 0% 0% no-repeat padding-box;
}
#popup-login .content
{
  max-width: 550px;
  display: flex;
  background:transparent;
  flex-direction: column;
}
.title
{
  font-size:35px;
  margin-top: 40px;
  line-height: 1;
  width:80%;
  margin-bottom: 30px;
}
#popup-login .content .left .p.little a 
{
  color:#000;
  font-size:16px;
  text-decoration: underline;
}
#popup-login .content .left .p.little
{
  color:#999;
  font-size:16px;
  margin-bottom:20px;
}
#popup-login .content .left.white-bg
{
  background:#fff;
}
#popup-login .content .left
{
  
  order: 2;
  display: flex;
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  top:0;
  height:100%;
  background:#63FF2B;
  border-radius: 5px;
  width:100%;
  vertical-align: top;
 
 
}
.bar-content
{
  position:absolute;
 
  top:0;
  left:0;
  background-color: #999999;
  height:100%;
  border-radius: 5px;
}
.bar{
  position:absolute;
  width:50%;
  top:30px;
  left:20px;
  background-color: #F1F1F1;
  height:6px;
  border-radius: 5px;
}
#popup-login .content .right
{
  background:#fff;
  border-radius: 5px;
  margin-bottom:20px;
  left:0;
padding-left:20px;
width:100%;
padding-right:20px;
}
.error-message {
  position:absolute;
  text-align: right;
  bottom:-15px;
  right:0;
  color:red;
  font-size:11px;
}
.input-line {
  position:relative;
  
}
</style>
