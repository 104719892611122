<template>
    <div id="cart-items" v-bind:class="{downloadrow: onlycollab==false && displayDownloadRow && userScans.length==0}">
        <span v-if="cart_items">
            <div v-if="cart_items.length>0" class="cart-items-items">
                <div v-for="(cart_item, index) in cart_items" :key="index">

                    <!-- ACCESSOIRE -->
                    <div v-if="cart_item.glass.type=='accessoire'" class='cart-item'>
                        <img class='product-img' :src='photos[index]' @click="displayPhotoBig(photos[index])" />
                        <div class='product-details'>
                            <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span>
                            <a class='remove' @click='removeItem(cart_item.id)' :data-item='cart_item.id'>
                                <img :data-item='cart_item.id' width='20'
                                    src='../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png' />
                            </a>
                            <div class='name'>{{cart_item.glass.name}}

                                <p>{{cart_item.glass.description}}</p>
                            </div>
                            <div class='item-amount'>{{cart_item.amount}}€</div>
                        </div>
                    </div>
                    <!-- <div v-else-if="cart_item.glass.type=='collaboration'" class='cart-item'>
                        <img class='product-img' @click="displayPhotoBig(cart_item.product_variation_item.photos[0])" :src='cart_item.product_variation_item.photos[0]' />
                        <div class='product-details'>
                            <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span>
                            <a class='remove' @click='removeItem(cart_item.id)' :data-item='cart_item.id'>
                                <img :data-item='cart_item.id' width='20'
                                    src='../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png' />
                            </a>
                            <div class='name'>{{cart_item.glass.name}}
                                <p v-if="cart_item.product_variation_item!=null">
                                    {{cart_item.product_variation_item.name}}</p>
                                <p v-else>{{cart_item.glass.description}}</p>
                            </div>
                            <div class='item-amount'>{{cart_item.amount}}€</div>
                        </div>
                    </div> -->
                    <div v-else class='cart-item'>

                       <div class="left-cart-item">
                        <div class="btn-white-small save contenudesk" @click="likePhoto(cart_item.glass.liked==true ? true : false, cart_item.glass.like_id,cart_item.glass.id,index)">
                            <img v-if="cart_item.glass.liked == true" src="../../../../public/assets/images/save.svg" width="14" />
                            <img v-else src="../../../../public/assets/images/save.svg" width="14" />
                        </div>
                           
                            <CartImage :cart_item="cart_item" />
                          
                        </div>

                        <div class='product-details'>
                            <div class="btn-grey-small save contenumob" @click="likePhoto(cart_item.glass.liked==true ? true : false, cart_item.glass.like_id,cart_item.glass.id,index)">
                                <img v-if="cart_item.glass.liked == true" src="../../../../public/assets/images/save.svg" width="14" />
                                <img v-else src="../../../../public/assets/images/save.svg" width="14" />
                            </div>
                            <div class="actions-item">
                                <a v-if="cart_item.quantity==1" class='btn-grey-small min' @click='removeItem(cart_item.id)' :data-item='cart_item.id'>
                                    <img :data-item='cart_item.id' width='16'
                                        src='../../../../public/assets/images/delete.svg' />
                                </a>
                                <a v-else class='btn-grey-small min' @click.stop='minusCartItem($event,cart_item.id,cart_item.quantity)'>
                                    -
                                </a>
                                
                                <span class='btn-grey-small'>{{cart_item.quantity}}</span>
                                <div class='btn-grey-small pls' @click='plusCartItem($event,cart_item.id)'>
                                    <img width='14'
                                    src='../../../../public/assets/images/add.svg' />
                                </div>
                            </div>

                            <div class='name'>
                                {{cart_item.glass.name}}
                            </div>

                            <div class='item-amount'>{{cart_item.amount}}€</div>
                            
                            <br />
                            <div class='product-detail' @click="toggleCart('glass',index)" :class="{open:toggleGlass[index]}">
                                <label>
                                    <span v-if="cart_item.glass_option!==null && cart_item.glass_type!==null"><span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                        <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span> - {{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                    <span v-else-if="cart_item.glass_option!==null">
                                        <span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                        <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span>
                                        
                                        - <span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                    <span v-else-if="cart_item.glass_type!==null">{{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                    <span v-else><span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                </label>
                                
                                <div class="product_detail_content">
                                    <template v-if="language=='fr'">
                                        <!-- Verres :
                                        <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name}}</span>
                                        <span v-else>Aucune option</span>
                                        
                                        <span v-if="cart_item.glass_type!==null"><br />Type de verre :
                                            {{cart_item.glass_type.name}} </span> -->


                                        <span v-if="cart_item.tint!==null">Teinte de verre : {{cart_item.tint.name}}</span>

                                        <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                            <span
                                                v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                            <span
                                                v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                            <span
                                                v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                            <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                        </span>
                                    </template>
                                    <template v-else>
                                        Lenses :
                                        <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                                        <span v-else>No option</span>
                                        
                                        <span v-if="cart_item.glass_type!==null"><br />Lens type:
                                            {{cart_item.glass_type.name.replace('Sans correction','No correction')}} </span>


                                        <span v-if="cart_item.tint!==null"><br />Lens tint: {{cart_item.tint.name}}</span>

                                        <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                            <span
                                                v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                            <span
                                                v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                            <span
                                                v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                            <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <div v-if="language=='fr'"  class='product-detail' @click="toggleCart('frame',index)" :class="{open:toggleFrame[index]}">
                                
                                <div class="product_detail_content">
                                    <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                        <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                           <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-else>
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture">
                                             
                                                Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture.name}}
                                                <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture_style">
                                               <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture_style.name}}
                                                <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                            </span>
                                        </span>
                                    </div>


                                    <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                        Gravure :
                                        {{cart_item.engraving_text}}
                                        <span class='subtotal'>10€</span>
                                    </div>
                                </div>
                            </div>

                            <div v-else class='product-detail' @click="toggleCart('frame', index)" :class="{open:toggleFrame[index]}">
                               
                                <div class="product_detail_content">
                                    <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                        <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                           <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-else>
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture">
                                             
                                                Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture.name}}
                                                <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture_style">
                                               <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture_style.name}}
                                                <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                            </span>
                                        </span>
                                    </div>


                                    <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                        Engraving :
                                        {{cart_item.engraving_text}}
                                        <span class='subtotal'>10€</span>
                                    </div>
                                </div>

                                
                            </div>
                            
                        </div>

                        
                    </div>

                    <br />

                    <div class="personnalisation">
                        <div class="elem-inner" >
                            <div class="label-personnalisation" v-if="language=='fr'">Taille</div>
                            <div class="label-personnalisation" v-else>Size</div>
                            <div class="slash">/</div>
                            
                                <div class="head">
                                    <div class="toggles">
                                        <!-- <div v-if="betaAccess!=true" class="beta-toggle"><img src="../../../../public/assets/images/betanr.png" /></div>
                                        <div v-if="betaAccess==true" class="beta-toggle-good beta-toggle"><img src="../../../../public/assets/images/betanr.png" /></div> -->

                                        <template v-if="activeToggle[index]!='custom'">
                                            <div class="toggle" @click.stop="() => toggleSelect('custom', index)" v-bind:class="{active : activeToggle[index]=='custom',none : activeToggle[index]==null}">
                                                
                                                <span v-if="language=='fr'"><img src="../../../../public/assets/images/locked.svg" width="10" v-if="(cart_item.glass.custom_fit!=true)" />
                                                     Sur-mesure</span>
                                                <span v-else><img src="../../../../public/assets/images/locked.svg" width="10" v-if="(cart_item.glass.custom_fit!=true)" />
                                                     Custom-fit</span>

                                            </div>
                                        </template>

                                        <template v-else>
                                            <div class="toggle scan" :class="{opened:displayScans,active : activeToggle[index]=='custom',none : activeToggle[index]==null}" @click.stop="toggleDisplayScans">
                                                <div class="toggle-infos">
                                                    
                                                    <span v-if="language=='fr'">Sur-mesure</span>
                                                    <span v-else>Custom-fit</span>

                                                    <span v-if="activeToggle[index]=='custom'" class="selected_item">
                                                        <template v-if="cart_item.scan!=null">{{cart_item.scan.name}}</template>
                                                        <template v-else-if="selectedScan[index]">{{selectedScan[index]}}</template>
                                                        <template v-else>
                                                                <template v-if="language=='fr'">Sélectionner</template>
                                                                <template v-else>Select</template>
                                                        </template>
                                                    </span>
                                                </div>
                                                <div class="options-scan" v-if="displayScans">
                                                    <div v-if="language=='fr'" class="option"
                                                        @click.prevent="selectScan(index,null,null)">
                                                        <span>
                                                        <img src="../../../../public/assets/images/add.svg" class="icon-scan add" /> Nouveau scan</span>
                                                    </div>

                                                    <div v-else class="option"
                                                        @click.prevent="selectScan(index,null,null)">
                                                        <span>
                                                        <img src="../../../../public/assets/images/add.svg" class="icon-scan add" /> New scan</span>
                                                    </div>
                                                    <div class="option" v-for="scan in userScans" :key="scan.id" @click.prevent="selectScan(index,scan.id,scan.name)">
                                                        <span>
                                                        <img src="../../../../public/assets/images/star_plein.svg" v-if="scan.default_scan" class="icon-scan star" />
                                                        <img src="../../../../public/assets/images/star_vide.svg" v-else class="icon-scan star" />
                                                        {{scan.name}}</span>
                                                        <span class="date_scan">{{ scan.date }}</span>

                                                    </div>


                                                </div>
                                                
                                            </div>
                                        </template>

                                        <div class="toggle" @click="() => toggleSelect('size', index)" v-bind:class="{active : activeToggle[index]=='size',none : activeToggle[index]==null}">
                                            <span>One-size</span>   
                                        </div>
                                    </div>

                                   
                                   <div class="popup-size" v-if='displayOnMesurePopup[index]'>

                                    <div class="content">
                                        <span id="cross"
                                            @click="displayMesuresFn(index)">
                                            <img
                                                src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png"
                                                width="12" /></span>


                                        <div class="size-description">
                                            Sélectionner un scan de votre visage effectué au préalable sur
                                            l’application iOS Visages.<br />
                                            Le scan sera utilisé pour ajuster la lunette.<br /><br />
                                            Vous souhaitez effectuer un nouveau Scan? <br />
                                            <a>Sélectionnez "+ Nouveau scan"</a>
                                        </div>

                                    </div>
                                </div>

                                <div class="popup-size popup-size-new" @click="(e) => closeModal(e)" v-if='displayHowItWorkPopup[index]'>
                                    <div class="content">
                                            <span id="cross" @click="displayHowItWorkPopupToFalse"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                                            <div class="content-size-popup">
                                                <div class="left-size-popup">
                                                    <img src="../../../../public/assets/images/sizepopup.jpg" />
                                            
                                                </div>
                                                <div class="right-size-popup">
                                                    <div class="title-popup-size"><span v-if="langage=='fr'" >Explication de taille du modèle</span><span v-else >Size explanation</span></div>
                                                    <br />  <br />
                                                    <img src="../../../../public/assets/images/size2.jpg" class="size-popup-img" />
                                                    <br />
                                                    <div class="size-elements">
                                                        <div class="size-element">
                                                            
                                                            <div v-if="langage=='fr'" class="size-element-left">Taille</div>
                                                            <div v-else class="size-element-left">Size</div>
                                                            <div class="size-element-right">Standard</div>
                                                        </div>
                                                        <div class="size-element">
                                                            <div v-if="langage=='fr'" class="size-element-left">Faces</div>
                                                            <div v-else class="size-element-left">Front</div>
                                                            <div class="size-element-right">30x15cm</div>
                                                        </div>
                                                        <div class="size-element">
                                                            <div v-if="langage=='fr'" class="size-element-left">Branches</div>
                                                            <div v-else class="size-element-left">Temples</div>
                                                            <div class="size-element-right">30x15cm</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!-- <div class="popup-size" v-if='displayHowItWorkPopup[index]'>
                                    <div class="popup-size" v-if='displayHowItWorkPopup'>
                                        <div class="content">
                                            <span id="cross" @click="displayHowItWorkFn(index)"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="20" /></span>
                                            <div class="size-name">Taille 
                                                <span v-for="size in sizes" :key="size.id">
                                                    <span v-if="calculateSizePopup(index)==size.value">{{size.label}}</span>
                                                </span>
                                            </div>
                                            
                                            
                                            <div class="size_choice">
                                                <span v-for="size in sizes" :key="size.id">
                                                    <div class="size" @click="selectSize(index,size.value)" v-bind:class="{active : calculateSizePopup(index)==size.value}"><span>{{size.label}}</span></div>
                                                </span>
                                            </div>
                                            <div class="size-description">

                                                <span v-html="sizeSentenceFactory(calculateSizePopup(index))"></span>

                                            </div>
                                            <img src="../../../../public/assets/images/size.jpg" class="size-image" />
                                            
                                        </div>
                                    </div>

                                    </div> -->
                                    <!-- <div class="size_choice" v-if="activeToggle[index]=='size'">
                                        <div class="info">
                                            <a class="link" @click="displayHowItWork(index)"> <img
                                                    src="../../../../public/assets/images/question.svg" width="20" /></a>
                                        </div>

                                        <div class="popup-size" v-if='displayOnMesurePopup[index]'>

                                            <div class="content">
                                                <span id="cross"
                                                    @click="displayMesuresFn(index)">
                                                    <img
                                                        src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png"
                                                        width="20" /></span>


                                                <div class="size-description">
                                                    Sélectionner un scan de votre visage effectué au préalable sur
                                                    l’application IOS Senseye.<br />
                                                    Le scan sera utilisé pour ajuster la lunette.<br />
                                                    Vous souhaitez effectuer un nouveau Scan? <br />
                                                    <a href="#">Cliquez-ici</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="popup-size" v-if='displayHowItWorkPopup[index]'>
                                            <div class="popup-size" v-if='displayHowItWorkPopup'>
                                                <div class="content">
                                                    <span id="cross" @click="displayHowItWorkFn(index)"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="20" /></span>
                                                    <div class="size-name">Taille 
                                                        <span v-for="size in sizes" :key="size.id">
                                                            <span v-if="calculateSizePopup(index)==size.value">{{size.label}}</span>
                                                        </span>
                                                    </div>
                                                    
                                                    
                                                    <div class="size_choice">
                                                        <span v-for="size in sizes" :key="size.id">
                                                            <div class="size" @click="selectSize(index,size.value)" v-bind:class="{active : calculateSizePopup(index)==size.value}"><span>{{size.label}}</span></div>
                                                        </span>
                                                    </div>
                                                    <div class="size-description">

                                                        <span v-html="sizeSentenceFactory(calculateSizePopup(index))"></span>

                                                    </div>
                                                    <img src="../../../../public/assets/images/size.jpg" class="size-image" />
                                                    
                                                </div>
                                            </div>
                                    
                                        </div>
                                        <div class="title-size" v-if="sizes">
                                            <span v-if="userScans.length>0 && cart_item.scan!=null">Taille recommandée
                                                pour le scan {{selectedScan[index]}} :
                                                "{{recommandedSize[index]}}"</span>
                                            <span v-else>Taille
                                                <span v-for="size in sizes" :key="size.id">
                                                    <span v-if="cart_item.size==size.value">{{size.label}}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <span v-for="size in sizes" :key="size.id">
                                            <div class="size" @click="selectSize(index,size.value)"
                                                v-bind:class="{active : cart_item.size==size.value}"><span>{{size.label}}</span>
                                            </div>
                                        </span>
                                    </div> -->
                                </div>
                                <div class="infos-icon" @click.stop="displayHowItWork(index)">
                                    <img src="../../../../public/assets/images/infos.svg" width="20" />
                                
                                </div>
                            </div>
                        </div>
                            
                </div>
            </div>
            <div v-else>
                <span v-if="language=='fr'">Aucun produit dans le panier</span><span v-else>No product in the cart</span>
                
            </div>
        </span>
        <span v-else>
            <span v-if="language=='fr'">Aucun produit dans le panier</span><span v-else>No product in the cart</span>
        </span>
    </div>
    <div v-if="displayBigPhoto" @click="closeModal" class="popup-photo-big">
        <div class="content">
            <img src="../../../../public/assets/home/cross.svg" class="cross" @click="closePhotoBig" />


            <inner-image-zoom :src="bigPhoto" :zoomSrc="bigPhoto" class="photo"  />
        </div>
    </div>
</template>

<script>
import sizeSentenceFactory from '../../../data/collectionGlass/text_size.js'
import { useOrderItemStore } from '../../../store/orderItemStore'
import CartImage from "./CartImageComponent.vue";
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

export default {
    name: "CartItemComponent",
    props: ["language","cart_items","onlycollab","displayDownloadRow","user","userScans","cart","photos","toggleGlass","toggleFrame","activeToggle","togglePosition",
            "displayOnMesurePopup","displayPopupBg","displayHowItWorkPopup","sizes","selectedScan","recommandedSize","displayScans","displayAppPopup"],
            components: {
                CartImage,
    'inner-image-zoom': InnerImageZoom
  },
    methods: {

        likePhoto(glass,id,index,index2)
        {
            this.$emit('likePhotoEv', glass, id,index, index2)
        },

        closeModal($event)
        {
            
            if(jQuery($event.target).parents('.popup-photo-big .content').length)
            {
              
            }
            else
            {
                this.displayBigPhoto=false
                $event.preventDefault()
            }
            
        },
        displayPhotoBig(photo) {
            this.bigPhoto=photo
            this.displayBigPhoto=true
        },
        closePhotoBig() {
            this.displayBigPhoto=false
        },
        selectSizePopup(size) {
            this.activeSizePopup = size
        },
        minusCartItem(e,id,quantity){
            this.$emit('minusCartItemEv', e,id,quantity)
        },
        plusCartItem(e,id){
          
            this.$emit('plusCartItemEv', e,id)
        },
        removeItem(id){
            this.$emit('removeItemEv', id)
        },
        toggleCart(str,index){
            this.$emit('toggleCartEv', str,index)
        },
        toggleSelect(str, i){
            this.$emit('toggleSelectEv', str, i)
        },
        displayHowItWork(i){
            this.$emit('displayHowItWorkEv', i)
        },
        selectSize(i, value){
            this.$emit('selectSizeEv', i, value)
        },
        selectScan(i,id,name){
            this.$emit('selectScanEv',i,id,name)
        },
        displayLogin(){
            this.$emit('displayLoginEv')
        },
        displayMesuresFn(index){
            this.$emit('displayMesuresFnEv', index)
        },
        displayHowItWorkFn(index){
            this.$emit('displayHowItWorkFnEv', index)
        },
        toggleDisplayScans(){
            this.$emit('toggleDisplayScansEv')
        },
        displayAppPopupToTrue(){
            this.$emit('displayAppPopupToTrueEv')
        },
       
        
        calculateSizePopup(index) {
            if(this.cart_items[index].size!=null && this.cart_items[index].size!="") {
                return this.cart_items[index].size;
            }
            else {
                return "m";
            }
        }
                
    },
    data(){
      
        return{
            useOrderItem: useOrderItemStore(),
            sizeSentenceFactory,
            displayBigPhoto:false,
            bigPhoto:null,
            activeSizePopup: 'm',
        }
    },
    computed: {
    
    
    },
}
</script>

<style scoped>
.icon-scan.add 
{
    position:relative;
    top:2px;
    width:14px;
    margin-right:9px;
    margin-left:2px;
}
.icon-scan 
{
    opacity:1;
    width:20px;
    margin-right:5px;
}
.icon-scan.star
{
    position:relative;
    top:4px;
}
.toggle.scan.active .toggle-infos{
    display:flex;
    justify-content: flex-start;
    align-items: center;
}
.toggle.scan.active.opened::after
{
    transform: rotate(180deg);
}
.toggle.scan.active::after
{
    content:' ';
    position: absolute;
    right:9px;
    transition: all 0.3s;
    top:16px;
    width:8px;
    height:10px;
    background-size: 100% 100%;
    background-image:url('../../../../public/assets/images/arrow_select.svg');
}
.toggle-infos .selected_item
{
    color:#999999;
    margin-left:10px;
}
.elem-inner
{
    width:100%;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}
.head {
    width:75%;
}
.save
{
    position:absolute;
    left:20px;
    top:20px;
    z-index: 2;
}
.beta-toggle img 
{
    width:35px;
}
.beta-toggle-good img
{
    filter: brightness(0);
}
.beta-toggle-good
{
    background:#E3E3E3 !important;
    border:1px solid #000;

}
.beta-toggle
{
    position:absolute;
    background:#000000;
    padding:7px 14px;
    top:-15px;
    border-radius: 150px;
    left:-20px;
    z-index:2;
}
@media screen and (max-width:760px) {
    .options-scan .option
    {
        padding:7px 14px !important;
    }
    .personnalisation .toggles .toggle
    {
        padding:9px 14px !important;
        font-size: 13px !important;
    }
    .personnalisation .toggles .toggle:first-child
    {
        margin-right: 10px !important;
    }
    .elem-inner .label-personnalisation
    {
        display:inline-block;
        padding-left:14px;
    }
    .infos-icon
    {
        top:0;
        right:14px;
        position:absolute;
    }
    .head
    {
        margin-top:10px;
        width:100%;
    }
    .elem-inner .slash 
    {
        padding-top:0;
        margin-left:10px;
        display:inline-block;
    }
    .elem-inner
    {
        position: relative;
        font-size: 13px !important;
        display:block !important;
    }
    .btn-grey-small.save 
    {
        right:0;
        left:auto;
        top:20px;
        justify-content: center;
        align-items: center;
        display: flex !important;
    }
    .left-glass-option-content img
    {
        width:40% !important;
    }
    .popup-glass-option-content {
        flex-direction: column;
    }
    .left-glass-option-content
    {
        margin-bottom:20px;
    }
    .left-glass-option-content,.right-glass-option-content
    {
        width:100% !important;
    }
    .content-size-popup .left-size-popup,
    .content-size-popup .right-size-popup
    {
        width:100% !important;
    }
    .size-element,
    .content-size-popup{
        flex-direction:column;
    }
    .size-element-left,
    .size-element-right,
    
    .table-color .right-table,
    .table-color .left-table {
        display:block !important;
        width:100% !important;
        font-size:12px !important;
    }
    .size-element-right {
        padding-left:37px;
    }
    .size-element-right,
    .table-color .right-table
    {
        padding-top:10px;
    }
    .size-element-left,
    .table-color .left-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    }
    .col-title
    {
        width:45% !important;
    }
    .img-color {
        position:absolute;
        top:10px;
        right:10px;
        width:90px !important;
        height:90px !important;
    }
    .options-popup-texture .legend {
        width:100% !important;
        display:block;
        padding-top: 8px;
        margin-top:10px;
    border-top: 1px solid rgba(0,0,0,0.5);
    }
    .option-popup-texture span {
        margin-left:10px;
    }
    .img-option-popup-texture {
        width:20% !important;
    }
    .option-popup-texture {
        width:100% !important;
        display: flex;
    }
    .options-popup-texture {
        flex-direction:column;
    }
    .table-texture 

    {
        font-size:12px !important;
    }
    .title-popup-size,
    .title-texture
    {
        font-size:18px !important;
    }
    
    .title-color {
        font-size:18px !important;
        margin-top:50px !important;
    }
}
.right-glass-option-content
{
    width:70%;
    padding-left:10px;
}
.left-glass-option-content img {
    width:80%;
    display:inline-block;
}
.left-glass-option-content
{
    text-align: center;
    width:30%;
}
.popup-glass-option-content {
    display:flex;
    align-items: center;
}
.glass-type-popup-element {
    font-size:14px;
    padding-top:10px;
    line-height: 1;
    border-bottom:1px solid #ccc;
    padding-bottom:12px;
    width:100%;
}
.title-glass-type-popup {
    font-size:23px;
}
.title-popup-size .arrow-popup-image {
    
    top:0px;
    
}
.arrow-popup-image {
    display:inline-block;
    width:25px;
    margin-right: 10px;
    position: relative;
    top:7px;
    height:25px;
}
.title-popup-size
{
    font-size:23px;
    line-height: 1;
    display: flex;
    text-align: left;
}
.content-size-popup .size-element 
{
    display:table;
    width:100%;
    font-size: 14px;
    line-height: 1;
    border-bottom:1px solid #ccc;
    padding-bottom:12px;
}
.content-size-popup .size-element-right 
{
    display:table-cell;
    text-align: left;
    width:40%;
}
.content-size-popup .size-element-left 
{
    text-align: left;
    display:table-cell;
    width:60%;
}
.content-size-popup .right-size-popup
{
    width:60%;
}
.content-size-popup .left-size-popup
{
    width:40%;
}
.content-size-popup {
    display:flex;
    align-items:center;
    width:100%;
}
.personnalisation .toggles .toggle:first-child {
    margin-right:12px;
}
.elem-inner 
{
    font-size:16px;
    color:#999999;
}
.label-personnalisation
{
    padding-left:14px;
    padding-top:10px;
}
.infos-icon
{
    padding-top:10px;
    cursor: pointer;
}
.slash
{
    padding-top:10px;
 
}
.toggle img {
    display:inline-block;

    margin-right:5px;
}
@media screen and (max-width:760px)
{
    #cart-items .cart-item .remove
    {
        
    }
    .personnalisation .toggles {
        min-width: 220px !important;
    }
    .like 
    {
        position:absolute !important;
        right: 20px !important;
        left:auto !important;
        top:20px !important;
    }
    .actions-item {
       
    }
   
    body #cart-items .cart-item
    {
        padding:20px 0 !important;
    }
    #cart-items .cart-item .left-cart-item
    {
        
        position: relative;
    }
  .cart-item {
  
  }
  .btn-grey-small
{
    color:#000;
    text-decoration: none !important;
    background:#F1F1F1;
    display: flex;
    cursor: pointer;
    width:36px !important;
    height:36px !important;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
}

.btn-white-small
{
    background:#fff;
    display: flex;
    cursor: pointer;
    width:40px;
    height:40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.btn-grey-small:not(.contenumob)
{
    color:#000;
    text-decoration: none !important;
    background:#F1F1F1;
    display: flex;
    cursor: pointer;
    width:40px;
    height:40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.question-size {
    display:inline-block;
    position: relative;
    top: 30px;
    margin-left:10px;
    cursor: pointer;
}
.like {
    position: relative;
    top:0;
    min-width: 26px;
    margin-left:30px;
}
.popup-photo-big {
    background:rgba(0,0,0,0.7);
    position:fixed;
    left:0;
    top:0;
    z-index:9999999;
    width:100%;
    height:100%;
}
.popup-photo-big .content .cross {
    position:absolute;
    right:-20px;
    top:-20px;
    cursor:pointer;
    z-index:2;
}

.popup-photo-big .content {
    position:absolute;
    left:50%;
    background:#fff;
    top:50%;
    width:auto;
    
    max-width:80%;
    transform:translate(-50%,-50%);
}
.personnalisation .toggles .toggle-bg
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position:absolute;
    left:0;
    height:95%;
    top:50%;
    transform: translateY(-50%);
    z-index:1;
    width:50%;
    transition:all 0.3s ease-in-out;
}
.personnalisation .toggles .toggle.active {
    border: 1px solid #000;
    color: #000;
    background: #F1F1F1;
    flex-grow: 1;
}
.size-description {
    text-align: center !important;
    padding-top:50px;
    padding-bottom:50px;
    font-size: 14px;
}
.personnalisation .toggles .toggle.none 
{
    flex:1;
}
.personnalisation .toggles .toggle
{
    border: 1px solid #999999;
    padding: 10px 14px;
    border-radius: 5px;
    position: relative;
    font-size: 16px;
    transition:all 0.3s;
    color: #999999;
    text-align: left;
    z-index: 2;
    vertical-align: top;
    height: -moz-fit-content;
    height: fit-content;
}
.personnalisation .toggles
{
    display: flex;
    width: 100%;
}
.product_detail_content {
    position:relative;
   
}
.size_choice  .size.active
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.size_choice .size span {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.size_choice .size
{
    margin-bottom:-10px;
  cursor:pointer;
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    display:inline-block;
    border-radius:50%;
    vertical-align: top;
    /* padding-top:10px;
    padding-bottom:10px; */
    padding: 10px 15px;
    width:39px;
    height:39px;
    position: relative;
    margin-left:10px;
    margin-right:10px;
    color:rgba(103, 103, 103, 1);
    white-space: nowrap;
}


.actions-item {
    display: flex;
    justify-content: flex-start;
    margin-top:20px;
    position:relative;
   width: 100%;
    white-space: nowrap;
    z-index: 2;
}
.btn-grey-small.min
{
    margin-right:10px;
}
.btn-grey-small.pls
{
    margin-left:10px;
}

.info .link
{
    right:-5px;
    position:relative;
    top:0px !important;
    
}
#popup-cart .cart-content .product-detail label
{
    width:95%;
    margin:0;
  display: inline-block;
}
#popup-cart .cart-content .product-detail
{
  font-size:16px;
  color: #999999;
}
#popup-cart .cart-content .product-detail .subtotal
{
  display: inline-block;
  margin-right: 0;
      width: auto;
    clear: none;
}

.personnalisation
{
    font-size:12px;

}
#cart-items .cart-item {

}
.size_choice
{
  margin-bottom:40px;
  text-align: center;
  display:block;
  border-radius:8px;
  width:290px;
  position:relative;
  padding:20px;
  vertical-align: top;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

}

.head .info .link
{
    color:rgba(103, 103, 103, 1);
    cursor:pointer;
}
.head .info
{
      position:absolute;
      top:5px;
      right:10px;
        
        text-align: right;
    
}

.title-size
{
    padding-left:10px;
    padding-right:10px;
    margin-top:-15px;
    color:rgba(103, 103, 103, 1);
}
@media screen and (min-width:760px)  and (max-width:1300px) {
    
    .personnalisation .toggles .toggle
    {
        font-size: 14px !important;
        padding:11px 14px !important;
    }
    #popup-cart .cart-content .product-detail label,
    .elem-inner,
    #popup-cart .cart-content .product-detail .subtotal,
    #popup-cart .cart-content .product-detail
    {
        font-size: 14px !important;
    }
#cart-items .cart-item .product-details .item-amount,
    #cart-items .cart-item .name
    {
        font-size:20px;
    }
}
@media screen and (max-width:760px) {

    .actions-item
    {
        margin-top:0 !important;
    }
    .btn-grey-small
    {
        width:36px;
        height: 36px;
    }
    #popup-cart .cart-content .product-detail label
    {
        font-size:13px !important;
    }
    #cart-items .cart-item .product-details .item-amount,
    #cart-items .cart-item .name
    {
        font-size:16px;
    }
   .title-size {
    margin-top:0 !important;
    margin-bottom:-5px !important;
   } 
   .btn-grey-small
   {
    width:36px;
    height: 36px;
   }
}

.personnalisation .toggles .toggle-bg
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position:absolute;
    left:0;
    height:95%;
    top:50%;
    transform: translateY(-50%);
    z-index:1;
    width:50%;
    transition:all 0.3s ease-in-out;
}


.product_detail_content span.detail-custom {
    display:block;
}

#popup-cart .cart-content .product-detail.scans label b {
    font-weight: 600; 
}
#popup-cart .cart-content .product-detail.scans
{
  border-bottom:0 !important;
  font-weight: 600;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border: 1px solid rgba(112, 112, 112, 0);
border-radius: 8px;
    max-width: 280px;
    padding:10px 20px;
}
.options-scan .option:hover {
    background: rgba(0, 0, 0, 0.1);
}

.options-scan {
    max-height: 160px;
    overflow-y: auto;
    position: relative;
    left: 0px;
    z-index: 999;
    top:10px;
    margin-left:-14px;
    margin-right:-14px;
    padding-bottom:3px;
}

.options-scan .option {
    padding: 11px 10px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    
    cursor: pointer;
    
}
.scan-choice::before
{
  position:absolute;
  content:' ';
  width:17px;
  height:17px;

  background-size:100% 100%;
  left:10px;
 
  top:10px;
  background-image:url('../../../../public/assets/images/icon_scan.svg');
}
.scan-choice.opened::after {
    transform: rotate(-90deg);
    right:-7px;
}
.scan-choice::after
{
  position:absolute;
  content:' ';
  width:38px;
  height:38px;
  transform: rotate(90deg);
  background-size:100% 100%;
  right:5px;
  color:rgba(16, 132, 255, 1);
  top:-2px;
  background-image:url('../../../../public/assets/images/arrow_scan.svg');
}
.scan-choice
{
    margin-top:5px;
    font-size:10px;
    font-weight: 600;
  left:0;
  cursor: pointer;
  background: transparent linear-gradient(90deg, #5D2BFF 0%, #0000E0 100%) 0% 0% no-repeat padding-box;
border-radius: 8px;
  color:#fff;
  padding-top:10px;
  padding-bottom:10px;
  position:relative;
  padding-left:40px;
  padding-right:0px;
}
.product-detail {
     position:relative;
}
.product-detail:first-child .right-icon{
    top:6px;
}
.product-detail .right-icon {
  float:right;
  position:absolute;
  right:15px;

   top:6px;
}
.product-detail.open .right-icon.minus{
  display:block;
}
.product-detail.open .right-icon.plus{
  display:none;
}
.right-icon.minus {
  display: none;
}


body .popup-size #cross {
    position:absolute !important;
    right:-10px !important;
    top:-10px !important;
    left:auto !important;
 
    z-index: 2;
    box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16) !important ;
    padding:8px !important;
    background:#fff !important;
    border-radius: 50% !important;
    cursor:pointer;
}
.popup-size .content
{
    z-index: 1;
    background:#fff;
    padding:20px;
    position:absolute;
    top:50%;
    left:50%;
    z-index:8;
    font-size:13px;
    min-width:440px;
    max-width:440px;
    max-height:100vh;
    transform:translate(-50%,-50%);
    box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16) ;
}
.popup-size {
    position:fixed;
    left:0;
    top:0;
    background:rgba(0, 0, 0, 0.5);
    z-index:9999;
    width:100%;
    height:100%;
}
.cart-items-items {
  display:flex;
  gap:40px;
  flex-direction: column-reverse;
}

@media screen and (max-width:760px) {
  

   
    #popup-login .content .left {
        width:100% !important;
        z-index:1;
        height:120px !important;
        position:relative !important;

    }
    #popup-login .content .right {
        width:100% !important;
        left:0 !important;
        z-index: 2;
        
    }
}
</style>