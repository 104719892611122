<template>
    <div class="container-bg" @click="closeModal">
        <div class="container" v-if="modalSaving">
            <div class='btn-grey-small cross' @click="closeModal">
                <img width='14'
                src='../../../public/assets/images/close_small.svg' />
            </div>

            <div class="content-saving">
                <div class="title-saving">
                    Product added to favorites
                </div>
                <div class="content">
                    <div class="img-content">
                        <img v-for="(layer,indexlayer) in photo" :key="indexlayer" :style="'opacity:'+getOpacityElement(indexlayer)+';'" :src="calculatePhoto(layer)" :class="{img:true,'render-layer2':indexlayer>0}"  />
                    </div>
                    <div class="name">
                        {{ glass.name }}<br />
                        {{ glass.brand_name }}<br />
                        {{ glass.price }}€<br />
                    </div>
                </div>
                <div class="button" @click="goToFavorites()">
                    View your favorites
                </div>
            </div>
        </div>
        <div class="container" v-else-if="like==true" >
            <span id="cross" @click="hide"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>

            <div v-if="language=='fr'" class="txt-container">
                <center><img src="../../../public/assets/images/heart.png" width="34" /></center>
                <h3 class="title">PRODUIT AJOUTÉ AUX FAVORIS</h3>
                <p class="txt">Vos favoris sont accessibles depuis votre page compte, située dans l’onglet ‘Favoris & Enregistrements’.</p>
            </div>
            <div v-else class="txt-container">
                <center><img src="../../../public/assets/images/heart.png" width="34" /></center>
                <h3 class="title">PRODUCT ADDED TO FAVORITES</h3>
                <p class="txt">Your favorites products are available on your account page, located in 'the Favorites & Savings' toggle.</p>
            </div>
            <div v-if="language=='fr'"  class="actions">
                <button class="btn white" @click="hide">Retour à la collection</button>
                
                <button class="btn" @click="goToLikes()" >Voir vos favoris</button>
            </div>
            <div v-else class="actions">
                <button class="btn white" @click="hide">Back to collection</button>
                
                <button class="btn" @click="goToLikes()" >See your favorites</button>
            </div>
            
        </div>
        <div class="container" v-else>
            <div class="txt-container">
                <!-- <h3 class="title">{{title}}</h3> -->
                <p v-if="title" class="txt">
                    <span v-html="title"></span>
                  </p>
            </div>
            <div class="actions"  v-if="language=='fr'">
                <button class="btn" @click="hide">Fermer</button>
             
                <button v-if="like==true" class="btn" @click="gotoLikes()">Cliquez ici pour voir les favoris</button>
                <button v-if="favorite==true" class="btn" @click="goToFavorites()" >Cliquez ici pour voir les enregistrements</button>
            </div>
            <div class="actions"  v-else>
               
                <button class="btn"  @click="hide">Close</button>
                <button v-if="like==true" class="btn" @click="gotoLikes()">Click here to see the favorites</button>
                <button v-if="favorite==true" class="btn" @click="goToFavorites()" >Click here to see the savings.</button>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: "OverlayComponent",
    props:["title", "txt", "favorite", "save", "like","language","modalSaving","glass","photo"],
    methods: {
        calculatePhoto(layer_info) {

            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }



            return url

        },
        getBrightnessElement(layer) {
        
            if(this.photo[layer].presets.brightness != null) {
                return this.photo[layer].presets.brightness/100
            }
            else {
                return 1
            }
        },
        getHueElement(layer) {

            if(this.photo[layer].presets.hue != null) {
                return this.photo[layer].presets.hue/1.7
            }
            else {
                return 0
            }
        
        },
        getSaturationElement(layer) {
            if(this.photo[layer].presets.saturation != null) {
                return this.photo[layer].presets.saturation/100
            }
            else {
                return 1
            }
        
        },
        getOpacityElement(layer) {
            if(this.photo[layer].presets.opacity != null) {
                return this.photo[layer].presets.opacity/100
            }
            else {
                return 1
            }
        
        },
        goToFavorites() {
            this.$emit('remove')
            this.$router.push({ name: 'likes' , params: { toggle: 'favorites' } })
        },
        gotoLikes(){
            this.$emit('remove')
            this.$router.push({ name: 'likes' })
        },
        hide() {
          
            this.$emit('remove')
        },
        closeModal($event)
        {
            
            if (jQuery($event.target).parents('.container').length) {

            }
            else
            {
              this.$emit('remove')
            }
        },
    }
}
</script>

<style scoped>
.button
{
    background:#63FF2B;
    color:#000;
    border-radius: 5px;
    text-decoration: none !important;
    text-transform: none;
    font-weight: 400;
    text-align: left;
    font-size:16px;
    padding:10px 14px;
    width:100%;
}
.content-saving .name
{
    width:70%;
    font-size: 16px;
    padding-left:20px;
}
.content-saving .content
{
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    margin-top:20px;
    margin-bottom:20px;
}
.title-saving
{
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1;
    font-size:42px;
}
.cross 
{
    position: absolute;
    right: 20px;
    top:20px;
}
.btn-grey-small
{
    background:#F1F1F1;
    display: flex;
    cursor: pointer;
    width:40px;
    height:40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.render-layer2
{
  position:absolute;
  left:0;
  top:50%;
  transform:translateY(-50%);
  width:100%;
  height:auto;
}
.img-content
{
  position:relative;
  left:0;
  background:#f1f1f1;
  top:0;
  overflow: hidden;
  width:30%;
  height:100%;
  padding-top:15px;
  padding-bottom:15px;
}
.actions .btn {
    margin-left:10px;
    margin-right:10px;
}
.container-bg {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 10000;
    height:100%;
    background:rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
}
.container{
    background-color: white;
    width: auto;
    max-width: 500px;
    padding: 1.5rem;
    border-radius: 5px;
    font-size: 1rem;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}

.txt-container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    text-align: center;
}

.title{
    font-size: 35px;
    margin-bottom: 0.5rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

.txt {
    color: #000;
    font-size:14px;
    padding-bottom: 0;
}

.btn.white
{
    background:#fff;
    color:#000;
}
#cross
{
  position: absolute;
  right:-10px;
   top:-10px;
    cursor: pointer;
    border-radius: 50%;
    padding: 7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
.title,.txt 
{
    width:70%;
    margin-left:auto;
    margin-right: auto;
}
.btn{
    border:0;
    background: #000 ;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    width: 100%;
    padding:10px 14px;
    
    border:1px solid black;
    display: block;
    font-weight: 400;
    text-decoration: none !important;
    text-transform: none;
    font-size: 16px;
    transition: background-color 0.15s ease-in-out;
}

.btn:hover {
    background: rgba(0, 0, 0, 0.8);
    color:#fff;
}



.bounce-enter-active {
    animation: bounce-in .5s ease-out both;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse ease-in both;
}

.actions{
    padding-bottom:1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}
@media screen and (min-width: 760px) and (max-width: 1300px){
    .content-saving .name
    {
        font-size:14px !important;
    }
    .btn{
        font-size:14px !important;
        padding:11px 14px !important;
    }
    .title-saving
     {
        font-size: 35px !important;
        width:75%;
     }
     .button
     {
        font-size:14px !important;
        padding:11px 14px !important;
     }
}
@media screen and (max-width: 760px){
    .btn{
        font-size: 13px;
    }
    .title-saving
     {
        font-size: 25px;
        padding-left: 0;
        padding-right: 0;
        width:85%;
     }
     .content-saving .content
     {
        padding-left: 0;
        padding-right: 0;
     }
     .button
     {
        font-size: 13px;
     }
     .cross
     {
        top:10px;
        right: 10px;
     }
     .btn-grey-small
     {
         width:36px;
         height:36px;
     }
	.container{
        width: 80%;
    }
    .container .title {
        font-size: 16px !important;
    }
    .actions
    {
        flex-direction: column;
    }
    .btn 
    {
        margin-bottom:10px;
    }
    .title,.txt 
{
    width:100%;
}
}

</style>
